/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/alt-text */
import styled from '@emotion/styled'
import useTimeout from '@rooks/use-timeout'
import clsx from 'clsx'
import PropTypes, { InferProps } from 'prop-types'
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useUIDSeed } from 'react-uid'
import SwiperCore, { Autoplay, Grid, Navigation } from 'swiper'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'

import { MappedVoyoSlide, ParsedVoyoSlide } from '../../helpers/middleware/parseVoyoSlider'
import { useGTM } from '../../hooks'
import useDeviceType from '../../hooks/layout/useDeviceType'
import { useVoyoData } from '../../selectors/ads'
import { MergeTypes } from '../../types/helpers/MergeTypes'
import { AntiClsFrame } from '../AntiClsFrame'
import { withErrorBoundary } from '../ErrorBoundary/ErrorBoundary.component'
import Icon from '../Icon/Icon.component'
import ArrowNetIcon from '../Icon/icons/ArrowNet'
import VoyoCard, { voyoCardImageRatio } from './components/Card/Card.component'
import { VoyoCallToAction } from './VoyoCallToAction'
import VoyoSliderStyled, {
    voyoSliderMaxHeightMd,
    voyoSliderMaxHeightSm,
    voyoSliderMaxWidthMd,
    voyoSliderMaxWidthSm
} from './VoyoSlider.style'

SwiperCore.use([Autoplay, Navigation])
const Div = styled.div()

const VoyoSliderAntiClsFrame = ({ children }) => (
    // @ts-expect-error: js component props are f-ed
    <AntiClsFrame minHeightXs={350} minHeightMd={250}>
        {children}
    </AntiClsFrame>
)

const isNotSwiper = (deviceType: string, deviceProp: string) =>
    !deviceType || (deviceProp !== 'any' && deviceType !== deviceProp)

const SwiperComponent = forwardRef<any, any>(({ deviceType, deviceProp, ...props }, ref) => {
    if (isNotSwiper(deviceType, deviceProp)) {
        return <Div {...props} ref={ref} />
    }

    return <Swiper {...props} ref={ref} />
})

const useVoyoSlideDimensions = (slidesPerViewWide: number, widthRatioSm = 0.6, gutterRatioSm = 0.1) =>
    useMemo(() => {
        const targetSlideWidth = voyoSliderMaxHeightMd * voyoCardImageRatio
        const targetSlideWidthSmRounded = Math.round(voyoSliderMaxHeightSm * widthRatioSm * 100) / 100
        const targetSlideWidthMdRounded = Math.round(targetSlideWidth * 100) / 100
        const percentageTakenBySlides = (((slidesPerViewWide || 6) * targetSlideWidth) / voyoSliderMaxWidthMd) * 100
        const percentMarginPerSlide = (100 - percentageTakenBySlides) / ((slidesPerViewWide || 6) - 1)
        return [
            voyoSliderMaxWidthSm * gutterRatioSm,
            Math.floor(percentMarginPerSlide * voyoSliderMaxWidthMd * 100) / 10000,
            targetSlideWidthMdRounded,
            targetSlideWidthSmRounded
        ]
    }, [slidesPerViewWide])

const useVoyoParsedSlidesWithinserts = ({
    slides,
    defaultSlide,
    isWide,
    slidesPerViewWide
}: Pick<VoyoSliderProps, 'slides' | 'defaultSlide' | 'isWide' | 'slidesPerViewWide'>) => {
    const [deviceType] = useDeviceType()
    const parsedSlides = useMemo(
        () => (slides || []).map((slide, index) => ({ ...slide, id: getSlideId(slide, index.toString()) })),
        [slides]
    )
    return useMemo(() => {
        if (parsedSlides.length === 0) return []
        if (!defaultSlide) return parsedSlides
        if (!isWide || deviceType === 'mobile') {
            return parsedSlides
        }

        let insertIndex = 0

        return parsedSlides.reduce((list, elem, i) => {
            list.push(elem)
            if ((i + 1) % ((slidesPerViewWide || 6) - 1) === 0) {
                list.push({
                    ...defaultSlide,
                    isInserted: true,
                    mobileHidden: true,
                    id: getSlideId(defaultSlide, insertIndex.toString())
                })
                insertIndex += 1
            }
            return list
        }, [] as MappedVoyoSlide[])
    }, [parsedSlides, defaultSlide, slidesPerViewWide, deviceType, isWide])
}

const getSlideId = (slide: ParsedVoyoSlide, prepend?: string) =>
    `${(prepend?.length || 0) > 0 ? `${prepend}-` : ''}${slide.title.toLowerCase().replace(/ */gim, '')}`
type PaginationButtonProps = { onClick?: () => void; rotate?: number; className?: string }
const PaginationButton = forwardRef<HTMLElement, PaginationButtonProps>(
    ({ onClick, rotate = 0, className }: PaginationButtonProps, ref) => {
        return (
            <Icon
                // @ts-expect-error: icon props are miffed
                icon={ArrowNetIcon}
                ref={ref}
                onClick={onClick}
                rotate={rotate}
                className={className}
                viewBox="-5 -5 20 25"
            />
        )
    }
)

type VoyoSliderTypeOverrideI = {
    slides: ParsedVoyoSlide[]
    defaultSlide: ParsedVoyoSlide
    device: 'desktop' | 'mobile' | 'any'
    deviceProp: 'any' | 'desktop' | 'mobile'
}
const VoyoSlider = ({
    id,
    title,
    slides,
    defaultSlide,
    slidesPerViewWide,
    buttonUrl,
    className,
    isWidget,
    isWide: isWideinput,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ImageComponent,
    device,
    deviceProp
}: MergeTypes<InferProps<typeof VoyoSlider.propTypes>, VoyoSliderTypeOverrideI>) => {
    if (!ImageComponent) throw new Error('ImageComponent is required')
    const { sendDataToGTM } = useGTM()
    const uid = useUIDSeed()
    const [deviceType, isDesktop] = useDeviceType()
    const swiperRef = useRef<SwiperRef>()
    const [isMounted, setIsMounted] = React.useState(false)

    const previousButtonRef = useRef<HTMLElement>(null)
    const nextButtonRef = useRef<HTMLElement>(null)
    const isWide = useMemo(() => {
        if (device === 'mobile') return false
        return isWideinput
    }, [device, isWideinput])

    const slidesWithInserts = useVoyoParsedSlidesWithinserts({ slides, defaultSlide, isWide, slidesPerViewWide })
    const isIzbori = ['4241fbfe-ed30-11ee-aa5d-7ecc6c482c09', 'e0669948-ed2b-11ee-88d9-faa67538f252'].includes(id)

    useEffect(() => {
        setIsMounted(true)
        return () => {
            setIsMounted(false)
        }
    }, [])

    const [swiperInitialised, setSwiperInit] = React.useState(false)

    useEffect(() => {
        if (!swiperInitialised) return
        const swiper = swiperRef?.current?.swiper
        if (!swiper) return
        swiper.update()
    }, [deviceType, isWide, swiperInitialised])

    const onSwiperInit = useCallback(
        (swiper: SwiperCore) => {
            if (isDesktop) {
                if (isIzbori) {
                    swiper.slideTo(6, 0, true)
                } else {
                    swiper.slideTo(Math.floor(slidesWithInserts.length / 2), 0, true)
                }
            }
            setSwiperInit(true)
        },
        [isDesktop, slidesWithInserts]
    )
    const { ref, inView } = useInView({ initialInView: false, threshold: 0.9, triggerOnce: true })

    const [canAutoplay, setAutoplay] = useState(false)
    const autoplayTimeout = useTimeout(() => {
        setAutoplay(true)
    }, 5000)
    useEffect(() => {
        if (!isMounted || !swiperInitialised) return
        autoplayTimeout.start()
        return () => {
            autoplayTimeout.clear()
        }
    }, [isMounted, swiperInitialised])

    const autoplay = useMemo(() => {
        if (canAutoplay && inView) {
            return {
                delay: 4000,
                disableOnInteraction: true
            }
        }

        return undefined
    }, [canAutoplay, inView])

    useEffect(() => {
        const swiper = swiperRef?.current?.swiper
        if (!swiper) return
        if (!autoplay) {
            swiper.autoplay.stop()
            return
        }
        swiper.autoplay.start()
    }, [autoplay])

    const slidePrevious = useCallback(() => {
        const swiper = swiperRef?.current?.swiper
        if (swiper) {
            swiper.slidePrev()
        }
    }, [])

    const slideNext = useCallback(() => {
        const swiper = swiperRef?.current?.swiper

        if (swiper) {
            swiper.slideNext()
        }
    }, [])

    useEffect(() => {
        if (inView) {
            sendDataToGTM({
                event: 'gtm.View',
                eventCategory: isWidget ? 'VoyoSlider - naslovnica' : 'VoyoSlider - članak',
                eventAction: `View ${title}`,
                eventLabel: `${title || 'VoyoSlider'} - ${buttonUrl}`
            })
        }
    }, [inView, isWidget, title, buttonUrl])

    const gtmDataHandler = gtmData => {
        sendDataToGTM(gtmData)
    }
    const [spaceBetweenSm, spaceBetweenMd, targetSlideWidthMd, targetSlideWidthSm] = useVoyoSlideDimensions(
        slidesPerViewWide || 6
    )

    const swiperNavigationOptions = useMemo(
        () => ({
            prevEl: previousButtonRef.current,
            nextEl: previousButtonRef.current
        }),
        []
    )

    if (!slidesWithInserts?.length) {
        return null
    }

    const isDiv = isNotSwiper(deviceType, deviceProp)

    return (
        <VoyoSliderStyled
            id={`slider_${id}`}
            className={className || undefined}
            ref={ref}
            isWide={isWide || false}
            itemWidthMd={targetSlideWidthMd}
            itemWidthSm={targetSlideWidthSm}
            gutterMd={spaceBetweenMd}
            gutterSm={spaceBetweenSm}
            isIzbori={isIzbori}>
            <div className="VoyoSlider_container">
                <SwiperComponent
                    ref={swiperRef}
                    className={clsx('VoyoSlider_swiper', !swiperInitialised && 'ssr')}
                    id="voyo_slider_swiper"
                    deviceType={deviceType}
                    deviceProp={deviceProp}
                    {...(!isDiv && {
                        touchMoveStopPropagation: false,
                        onInit: onSwiperInit,
                        spaceBetween: !swiperInitialised || !isDesktop || !isWide ? spaceBetweenSm : spaceBetweenMd,
                        slidesPerView: !swiperInitialised || !isDesktop || !isWide ? 'auto' : slidesPerViewWide || 6,
                        grid: { rows: 1 },
                        loop: true,
                        autoplay,
                        modules: [Grid],
                        navigation: swiperNavigationOptions
                    })}>
                    {slidesWithInserts?.map(item => (
                        <SwiperSlide
                            key={uid(item.id)}
                            className={clsx(
                                'VoyoSlider_item',
                                item.isInserted && 'VoyoSlider_item_inserted',
                                !swiperInitialised && 'ssr'
                            )}>
                            <VoyoCard
                                onClick={() =>
                                    gtmDataHandler({
                                        event: 'gtm.Click',
                                        eventCategory: isWidget ? 'VoyoSlider - naslovnica' : 'VoyoSlider - članak',
                                        eventAction: `Click ${title}`,
                                        eventLabel: `${title} - ${buttonUrl}`
                                    })
                                }
                                ImageComponent={ImageComponent}
                                buttonHref={item.url || buttonUrl}
                                title={item.title}
                                imageId={item.image?.id}
                                imageView
                            />

                            <VoyoCallToAction
                                buttonHref={item.url || buttonUrl}
                                subtitle={item.subtitle}
                                className="VoyoSlider_item_cta"
                            />
                        </SwiperSlide>
                    ))}
                </SwiperComponent>

                <div className="VoyoSlider_pagination">
                    <PaginationButton
                        rotate={180}
                        className="VoyoSlider_pagination_button"
                        onClick={slidePrevious}
                        ref={previousButtonRef}
                    />
                    <PaginationButton
                        className="VoyoSlider_pagination_button"
                        onClick={slideNext}
                        ref={nextButtonRef}
                    />
                </div>
            </div>
        </VoyoSliderStyled>
    )
}
const commonPropTypes = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ImageComponent: PropTypes.elementType,
    isWidget: PropTypes.bool,
    isWide: PropTypes.bool,
    slidesPerViewWide: PropTypes.number,
    deviceProp: PropTypes.oneOf(['desktop', 'mobile', 'any']),
    device: PropTypes.oneOf(['desktop', 'mobile', 'any'])
}
VoyoSlider.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    logo: PropTypes.shape({
        id: PropTypes.string
    }),
    slides: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    defaultSlide: PropTypes.oneOfType([PropTypes.object, () => null]),
    buttonUrl: PropTypes.string,
    ...commonPropTypes
}
const commonDefaultProps = {
    isWidget: false,
    isWide: false,
    slidesPerViewWide: 6,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ImageComponent: undefined,
    deviceProp: 'any',
    device: 'any'
}
VoyoSlider.defaultProps = {
    className: undefined,
    title: 'Voyo',
    // showLogo: undefined,
    logo: undefined,
    slides: undefined,
    defaultSlide: undefined,
    buttonUrl: undefined
    // disclaimer: undefined,
    // sourceVariant: VoyoSliderSourceVariants.ITEMS
}

export type VoyoSliderProps = MergeTypes<InferProps<typeof VoyoSlider.propTypes>, VoyoSliderTypeOverrideI>

const VoyoSliderWithData = ({
    id,
    ...props
}: MergeTypes<InferProps<typeof VoyoSliderWithData.propTypes>, Pick<VoyoSliderProps, 'device' | 'deviceProp'>>) => {
    const voyoData = useVoyoData()
    if (!(typeof id === 'string') || !voyoData[id]) return null
    return <VoyoSlider {...voyoData[id]} {...props} />
}
VoyoSliderWithData.propTypes = {
    ...commonPropTypes,
    id: PropTypes.string
}
VoyoSliderWithData.defaultProps = {
    ...commonDefaultProps,
    id: undefined
}

const VoyoSliderWithDataWrapped = withErrorBoundary(VoyoSliderWithData, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    FallbackComponent: () => null,
    onError: (error, componentStack) => {
        // eslint-disable-next-line no-console
        console.error('[VoyoSliderWithData]: ', error, componentStack)
    }
})

export { VoyoSliderWithDataWrapped as VoyoSliderWithData, VoyoSliderAntiClsFrame }

export default withErrorBoundary(VoyoSlider, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    FallbackComponent: () => null,
    onError: (error, componentStack) => {
        // eslint-disable-next-line no-console
        console.error('[VoyoSlider]: ', error, componentStack)
    }
})
