import { withTheme } from '@emotion/react'
import PropTypes from 'prop-types'

import aboveTheFoldItemsNumberHelper from '@hmn/rtl-web-core/helpers/aboveTheFoldItemsNumberHelper'

import { ArticleCard, articleCardSizeVariants } from '@hmn/rtl-net-ui/components/Article/components'
import { imageRatioVariants } from '@hmn/rtl-net-ui/components/Image'

import { Cell } from '../LayoutBuilder.style'

const cardVariationHandler = variation => {
    switch (variation) {
        case '1:1':
            return imageRatioVariants.CUSTOM_SQUARE
        case '16:9':
            return imageRatioVariants.CUSTOM_WIDE_SCREEN
        case '3:2':
            return imageRatioVariants.CUSTOM_LANDSCAPE
        default:
            return imageRatioVariants.CUSTOM_ORIGINAL
    }
}

const getImageScreenWidths = (gridWidth, variant) => {
    const isSquareImg = variant?.aspectRatio === '1:1'
    const isSmallSquareImg = variant?.aspectRatio === '1:1' && variant.type === 'beta'

    const xs = isSmallSquareImg ? '110px' : '100vw'
    const mdFactor = gridWidth / 12
    const md = isSmallSquareImg ? '110px' : `${Math.ceil(100 * mdFactor)}vw`

    return { xs, md, isSquareImg }
}
function WidgetContent({ items, position, defaultColors, theme, sortByMostRead, deviceProp }) {
    const itemCount = items?.length || 1
    const defaultColor = theme.colors?.brand || '#F15B24'
    const { y, w, h } = position

    if (!items?.length) {
        return null
    }

    const numberOfItemsInViewPort = aboveTheFoldItemsNumberHelper(itemCount, w, y, h)

    return (
        <Cell itemCount={itemCount} position={position} sortByMostRead={sortByMostRead}>
            {items?.map(({ key, item, variant }, index) => {
                const widthFactor = position.w < 4 ? 1 : itemCount
                const { xs, md, isSquareImg } = getImageScreenWidths(position.w / widthFactor, variant)
                const imageVariation = cardVariationHandler(variant?.aspectRatio)
                const isArticleGallery = item?.type === 'article' && item?.extended_attributes?.gallery && 'GALLERY'
                const contentMarkerVariant = isArticleGallery || item?.type.toUpperCase()

                return (
                    item && (
                        <ArticleCard
                            key={key}
                            // This is for debug
                            // item={{
                            //     ...item,
                            //     subtitle: `${deviceProp}`,
                            //     title: ` ${xs} ${md} ${index} ${JSON.stringify(position)} ${item.title}`
                            // }}
                            item={item}
                            sizeVariant={
                                articleCardSizeVariants[`1x${Math.round(12 / Math.round(position.w / widthFactor))}`]
                            }
                            imageWidth={isSquareImg ? '110px' : undefined}
                            imageHeight={isSquareImg ? '110px' : undefined}
                            imageVariation={imageVariation}
                            imageLazyLoad={index >= numberOfItemsInViewPort}
                            imagePreload={index < numberOfItemsInViewPort}
                            variant={variant?.type}
                            contentMarkerVariant={contentMarkerVariant}
                            isSquareImg={isSquareImg}
                            categoryColor={defaultColors ? defaultColor : item?.sitemap?.color}
                            isWidget
                            imageSizesMd={md}
                            imageSizesSm={xs}
                        />
                    )
                )
            })}
        </Cell>
    )
}

WidgetContent.propTypes = {
    position: PropTypes.shape({
        y: PropTypes.number,
        w: PropTypes.number,
        x: PropTypes.number,
        h: PropTypes.number
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    defaultColors: PropTypes.bool,
    sortByMostRead: PropTypes.bool,
    deviceProp: PropTypes.oneOf(['desktop', 'mobile'])
}
WidgetContent.defaultProps = {
    position: undefined,
    items: [],
    defaultColors: false,
    sortByMostRead: false,
    deviceProp: 'desktop'
}
export default withTheme(WidgetContent)
