/* eslint-disable quote-props, no-dupe-keys, max-len, @typescript-eslint/naming-convention */

import { createStyledComponent } from '../../../../helpers/components/createStyledComponent'

type StyleProps = {
    theme?: any
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = ({ theme }: StyleProps) => {
    return {
        height: 'auto'
    }
}

export default createStyledComponent({
    styles,
    tag: 'div'
})
