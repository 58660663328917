import { useEffect, useState } from 'react'

import { getListElastic, getOneElastic } from '@hmn/rtl-web-core/hooks'
import { handleError, returnEmptyOn4xx } from '@hmn/rtl-web-core/next/data-fetching/handle-errors'

import RequestType from './requestType'

const controlledPromise = callback => {
    let resolve
    const promise = new Promise(r => {
        resolve = r
    })

    return {
        promise: promise.then(callback),
        resolve
    }
}

const useWidgetDataAsync = requestList => {
    const [widgetData, setWidgetData] = useState([])

    useEffect(() => {
        setWidgetData(requestList.map(() => null))
        let isUnmounted = false
        const entityIds = requestList
            .filter(request => request.type === RequestType.ONE_ENTITY)
            .map(request => request.entityId)
        const highPriority = []
        const lowPriority = []
        requestList.forEach((request, index) => {
            let controlled
            switch (request.type) {
                case RequestType.ONE_ENTITY:
                    controlled = controlledPromise(() =>
                        getOneElastic(`entity/${request?.entityId}`, { enabled: !!request?.entityId }).catch(
                            handleError(returnEmptyOn4xx)
                        )
                    )
                    break
                case RequestType.LIST_FROM_CATEGORY:
                    controlled = controlledPromise(() =>
                        getListElastic(
                            `category/${request?.nodeId}/entities`,
                            {
                                exclude: entityIds
                            },
                            request.entityCount,
                            {
                                enabled: !!request?.nodeId
                            }
                        )
                    )
                    break
                default:
                    controlled = controlledPromise(() => null)
                    break
            }
            if (request.priority) {
                highPriority.push(controlled)
            } else {
                lowPriority.push(controlled)
            }
            controlled.promise.then(({ data }) => {
                if (!isUnmounted) {
                    setWidgetData(state =>
                        state.map((item, itemIndex) => {
                            if (index !== itemIndex) {
                                return item
                            }
                            return data
                        })
                    )
                }
            })
        })
        let timer = null
        Promise.allSettled(
            highPriority.map(controlled => {
                controlled.resolve()
                return controlled.promise
            })
        ).then(() => {
            timer = setTimeout(() => {
                lowPriority.forEach(controlled => controlled.resolve())
            }, 125)
        })
        return () => {
            isUnmounted = true
            window.clearTimeout(timer)
        }
    }, [requestList])

    return widgetData
}

export default useWidgetDataAsync
