import { Children, cloneElement, isValidElement, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints, useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { articleCardSizeVariants, articleCardVariants } from '../Article/components/Card'
import { imageRatioVariants } from '../Image'
import styles from './VideoPhotoBlock.style'

const VideoPhotoBlockStyled = styled.section(props => ({ ...styles(props) }))
function VideoPhotoBlock({ children, title, titleTagComponent: TitleTag, className, ...rest }) {
    const uid = useUIDSeed()
    const [isDesktop] = useBreakpoints('md')
    const itemElements = useMemo(() => Children.toArray(children).filter(child => isValidElement(child)), [children])
    const titleHtmlProps = useInnerHtml(title)

    const getCardVariant = index => {
        if (index === 0 && isDesktop) {
            return 'BETA'
        }
        if (index === 0 && !isDesktop) {
            return 'ALPHA'
        }
        return isDesktop ? 'ALPHA' : 'BETA'
    }

    const getCardSizeVariant = index => {
        if (index === 0 && isDesktop) {
            return '1x2'
        }
        if (index === 0 && !isDesktop) {
            return '1x4'
        }
        if (index !== 0 && !isDesktop) {
            return '1x6'
        }
        return isDesktop ? '1x4' : '1x2'
    }

    if (!itemElements.length) {
        return null
    }
    return (
        <VideoPhotoBlockStyled className={className} {...rest}>
            <div className="VideoPhotoBlock_inner">
                {title && (
                    <div className="VideoPhotoBlock_head">
                        <TitleTag className="VideoPhotoBlock_headTitle" {...titleHtmlProps} />
                    </div>
                )}
                <div className="VideoPhotoBlock_list">
                    {itemElements?.slice(0, 5).map((child, index) => (
                        <div
                            key={uid(child?.props?.item?.id || index)}
                            className={clsx('VideoPhotoBlock_item', index === 0 && 'isFirst')}>
                            {cloneElement(child, {
                                variant: articleCardVariants[getCardVariant(index)],
                                sizeVariant: articleCardSizeVariants[getCardSizeVariant(index)],
                                lazyLoad: true,
                                imageVariation:
                                    index === 0
                                        ? imageRatioVariants.CUSTOM_WIDE_SCREEN
                                        : imageRatioVariants.CUSTOM_LANDSCAPE,
                                showLead: index === 0
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </VideoPhotoBlockStyled>
    )
}

VideoPhotoBlock.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType
}
VideoPhotoBlock.defaultProps = {
    className: undefined,
    title: undefined,
    titleTagComponent: 'h1'
}

export default withErrorBoundary(VideoPhotoBlock, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[VideoPhotoBlock]: ', error, componentStack)
    }
})
