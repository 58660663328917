import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { Newsletter, newsletterVariants } from '@hmn/rtl-net-ui/components/Cta/components/Newsletter'

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetNewsletter({ style, position, ...rest }) {
    return (
        <Cell position={position}>
            <div className="newsletter">
                <Newsletter variant={position.w > 6 ? newsletterVariants.ALPHA : newsletterVariants.BETA} />
            </div>
        </Cell>
    )
}
WidgetNewsletter.propTypes = {
    position: PropTypes.shape({
        w: PropTypes.number
    }),
    style: PropTypes.string
}
WidgetNewsletter.defaultProps = {
    position: {},
    style: ''
}

export default WidgetNewsletter
