// this helper is used to determine if layout element will be in viewport on initial load
// when position.y is 13 we can not see the lyout element in initial viewport thats why GRID_ROWS_ABOVE_THE_FOLD = 12
// rowStar === position.y, widgetColumns === position.w, widgetRows === position.h
const GRID_ROWS_ABOVE_THE_FOLD = 12

const aboveTheFoldItemsNumberHelper = (itemCount, widgetColumns, rowStart, widgetRows) => {
    // if start row is out of the viewport there are no elemetns above the fold so return 0
    if (rowStart > GRID_ROWS_ABOVE_THE_FOLD) {
        return 0
    }
    // || 1 just to make sure nothing weird happens
    const itemGridRows = widgetRows || 1
    // calculate how much space is left above the fold in grid value
    const viewPortSpaceLeft = GRID_ROWS_ABOVE_THE_FOLD - rowStart
    // calculate how much rows can our widget fill
    const numberOfRowsLeft =
        (Math.floor(viewPortSpaceLeft / itemGridRows) || 1) + (viewPortSpaceLeft % itemGridRows ? 1 : 0)
    // calculate column width in grid columns value
    const widthFactor = widgetColumns < 4 ? 1 : itemCount
    const columnWidth = widgetColumns / (widthFactor > widgetColumns / 2 ? widgetColumns / 2 : widthFactor)
    // count how many items can we fit in the widget area
    const cellCount = Math.floor(widgetColumns / columnWidth) || 1
    // return number of items
    return cellCount * numberOfRowsLeft
}

export default aboveTheFoldItemsNumberHelper
