const colors = {
    white: '#FFFFFF',
    darkBlueGradientStart: '#0B3B79',
    darkBlueGradientEnd: '#129DF9',
    whiteTransparent: 'rgba(38, 77, 136, 0)',
    borderGrey: 'rgba(67, 67, 67, 0.2)',
    textWhite: '#FFFFFF',
    textDark: '#1e2a33',
    blackTransparent: 'rgba(0, 0, 0, 1)'
}

const styles = ({ theme, partiesList }) => ({
    '&.izbori': {
        gridColumnStart: 1,
        gridColumnEnd: 25,

        display: 'flex',
        flexDirection: 'column',

        overflow: 'hidden',

        maxWidth: '1160px',
        padding: '0',
        margin: '0 -16px',
        borderBottom: `2px solid ${colors.borderGrey}`,

        '@media (min-width: 768px)': {
            width: '100%',
            margin: '0 auto',
            border: `3px solid ${colors.borderGrey}`,
            borderRadius: '10px'
        },

        '.izbori': {
            '&__head': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px',
                background: 'linear-gradient(45deg, rgba(11,59,121,1) 0%, rgba(18,157,249,1) 100%);',

                '@media (min-width: 768px)': {
                    padding: '8px 30px 8px 13px'
                },

                '&_img': {
                    width: '80px',
                    height: 'auto',
                    '@media (min-width: 768px)': {
                        width: '100px',
                        height: 'auto'
                    }
                },

                '&_info': {
                    textAlign: 'right',
                    '@media (min-width: 768px)': {
                        textAlign: 'right'
                    },

                    '> p': {
                        fontStyle: 'normal',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '25px',
                        letterSpacing: '-0.03em',
                        color: colors.textWhite,
                        textTransform: 'uppercase',

                        '@media (min-width: 768px)': {
                            fontSize: '28px',
                            lineHeight: '17px',

                            '&.source': {
                                marginBottom: '-6px',
                                paddingTop: '4px'
                            }
                        }
                    }
                }
            },

            '&__cards': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'flex-start',
                flexWrap: 'nowrap',
                padding: '8px',
                overflowX: 'auto',
                WebkitOverflowScrolling: 'touch',
                scrollSnapType: 'x mandatory',

                '@media (min-width: 768px)': {
                    overflowX: 'hidden',
                    padding: '13px 10px 5px'
                }
            },

            '&__card': {
                display: 'flex',
                flexDirection: 'column',
                width: '138px',
                padding: '0 0 0 4px',
                scrollSnapAlign: 'start',
                boxSizing: 'content-box',

                '&:first-child': {
                    paddingLeft: '8px'
                },

                '&_img': {
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'flex-end',
                    width: '105px',
                    height: '127px',
                    borderRadius: '6px',
                    overflow: 'hidden',
                    transition: 'box-shadow 0.3s ease-in-out, background 0.3s ease-in-out',

                    '@media (min-width: 768px)': {
                        width: '138px',
                        height: '166px'
                    },

                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        opacity: 0.5,
                        backgroundImage: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)',
                        transition: 'opacity 0.3s ease-in-out'
                    },

                    '&:hover': {
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        '&::before': {
                            opacity: 0.6
                        }
                    },

                    '> div': {
                        display: 'contents'
                    }
                }
            },

            '&__card_info': {
                padding: '8px 0',
                '> p': {
                    fontWeight: 700,
                    fontSize: '12px',
                    lineHeight: '1',
                    letterSpacing: '-0.03em',
                    color: '#080808',

                    '@media (min-width: 768px)': {
                        fontSize: '15px'
                    }
                }
            }
        }
    },
    // test styles for broken images on global level
    // try to reuse
    '& .img-err': {
        position: 'relative',
        display: 'inline-block',
        overflow: 'hidden',

        '> img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'relative',

            color: '#1e2a33',
            fontSize: '14px',
            fontWeight: '700',
            textAlign: 'center'
        },

        '.imgErr, .imgError_alt': {
            display: 'none'
        },

        '.imgErr + .imgError_alt': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            padding: '0 10px',

            color: colors.textDark,
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '16px',
            textAlign: 'center',
            fontStyle: 'normal',
            textTransform: 'capitalize',

            backgroundColor: '#fff',

            '@media (min-width: 768px)': {
                fontSize: '18px',
                lineHeight: '20px'
            }
        }
    }
})

export default styles
