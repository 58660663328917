import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { FunCard } from '@hmn/rtl-net-ui/components/Fun/components'

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetFun({ position, title, showTextContent, items }) {
    const item = items?.[0]?.item

    if (!item) {
        return null
    }

    return (
        <Cell position={position} data-upscore-zone="fun">
            <div>
                <FunCard item={item} label={title} title={title} isText={showTextContent} />
            </div>
        </Cell>
    )
}

WidgetFun.propTypes = {
    position: PropTypes.shape({}),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            item: PropTypes.shape({
                id: PropTypes.string
            })
        })
    ),
    title: PropTypes.string,
    showTextContent: PropTypes.bool
}

WidgetFun.defaultProps = {
    position: {},
    items: [],
    title: undefined,
    showTextContent: false
}
export default WidgetFun
