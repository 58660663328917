import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Button } from '../../../Button'
import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { Ribbon } from '../../../Decoration/components'
import { HtmlContent } from '../../../HtmlContent'
import { ArrowRightIcon, Icon } from '../../../Icon'
import { Image, imageRatioVariants } from '../../../Image'
import { Link } from '../../../Link'
import styles from './FunCard.net.style'

const FunCardStyled = styled.div(props => ({ ...styles(props) }))

function FunCard({ label, isText, item, title, ...rest }) {
    const href = item?.sitemap?.href

    const getTitle = funTitle => {
        if (funTitle === 'Komnentar' || funTitle === 'komnentar') {
            return 'Kom<span>ne</span>n<span>t</span>ar'
        }
        return funTitle
    }

    return (
        <FunCardStyled {...rest} isText={isText}>
            <ConditionalWrapper condition={!!href} Wrapper={Link} href={href} as={href} title={label}>
                <Ribbon className="fun_ribbon" title={getTitle(label)} />
                {isText ? (
                    <div className="fun_text">
                        <div className="fun_text_title">{title || item?.title}</div>
                        {item?.body && <HtmlContent className="fun_text_content" data={item?.body} />}
                        <Button
                            title="Pročitaj cijeli vic"
                            className="fun_text_button"
                            width="25%"
                            widthMobile="100%"
                            simple
                            iconComponent={<Icon icon={ArrowRightIcon} />}>
                            Pročitaj cijeli vic
                        </Button>
                    </div>
                ) : (
                    <Image
                        variation={imageRatioVariants.CUSTOM_SQUARE}
                        image={item?.image?.id}
                        updatedAt={item?.image?.updated_at}
                        alt={label}
                        lazyLoad
                    />
                )}
            </ConditionalWrapper>
        </FunCardStyled>
    )
}

FunCard.propTypes = {
    isText: PropTypes.bool,
    label: PropTypes.string,
    item: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    title: PropTypes.string
}

FunCard.defaultProps = {
    isText: undefined,
    label: undefined,
    item: undefined,
    title: undefined
}

export default withErrorBoundary(FunCard, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[FunCard]: ', error, componentStack)
    }
})
