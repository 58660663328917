import ComponentType from './componentType'

const filterConfigByType = (type, neededConfig, discardedConfig) => {
    const {
        id,
        title,
        subtitle,
        lead,
        label,
        mainImage,
        isVisible,
        entityCount,
        entityList,
        style,
        size,
        navigation,
        richtext,
        nodeId,
        decorations,
        adPosition,
        bannerHeader,
        bannerDimension,
        bannerBody,
        handVariants,
        autoVariants,
        color,
        url,
        defaultColors
    } = neededConfig

    switch (type) {
        case ComponentType.CONTENT_HANDPICKED:
            return {
                isVisible: entityList?.length && isVisible,
                entityCount,
                entityList,
                defaultColors,
                variants: handVariants
            }
        case ComponentType.CONTENT_AUTOMATIC:
            return {
                defaultColors,
                isVisible: nodeId && isVisible,
                entityCount,
                nodeId,
                variants: autoVariants
            }
        case ComponentType.HEADING:
            return {
                isVisible: title && isVisible,
                title,
                size,
                style,
                color,
                decorations,
                url
            }
        case ComponentType.DIVIDER:
            return {
                isVisible,
                style
            }
        case ComponentType.NAVIGATION:
            return {
                isVisible: navigation && isVisible,
                navigation
            }
        case ComponentType.RICHTEXT:
            return {
                isVisible: richtext && isVisible,
                richtext
            }
        case ComponentType.SPECIAL:
            return {
                isVisible: entityList?.length && isVisible,
                title,
                subtitle,
                lead,
                mainImage,
                entityCount,
                entityList,
                variants: handVariants
            }
        case ComponentType.BORDER_BOX:
            return {
                isVisible: nodeId && isVisible,
                title,
                entityCount,
                nodeId,
                variants: autoVariants,
                url
            }
        case ComponentType.BANNER:
            return {
                isVisible,
                adPosition,
                bannerHeader,
                bannerDimension,
                bannerBody
            }
        case ComponentType.POLL:
            return {
                isVisible: entityList?.length && isVisible,
                id
            }
        case ComponentType.IFRAME:
            return {
                isVisible,
                ...discardedConfig
            }
        case ComponentType.PRODUCTGALLERIES:
            return {
                isVisible,

                ...discardedConfig
            }
        case ComponentType.FUN:
            return {
                isVisible: entityList?.length && isVisible,
                title,
                label
            }
        case ComponentType.BREAKINGNEWS:
            return {
                isVisible: entityList?.length && isVisible,
                title,
                subtitle,
                lead,
                mainImage,
                entityCount,
                entityList,
                variants: handVariants
            }
        case ComponentType.PHOTOVIDEO:
            return {
                isVisible: entityList?.length && isVisible,
                title,
                subtitle,
                lead,
                mainImage,
                entityCount,
                entityList,
                variants: handVariants
            }
        case ComponentType.NEWSLETTER:
            return {
                isVisible,
                style
            }
        case ComponentType.UGCCALL:
            return {
                isVisible,
                style
            }
        case ComponentType.LINKER:
            return {
                isVisible,
                style
            }
        case ComponentType.HOROSCOPE:
            return {
                isVisible,
                style
            }
        default:
            return {
                isVisible: true,
                neededConfig,
                discardedConfig
            }
    }
}

export default filterConfigByType
