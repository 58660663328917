import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import useDeviceType from '../../hooks/layout/useDeviceType'
import { AntiClsFrame } from '../AntiClsFrame'
import { ConditionalWrapper } from '../ConditionalWrapper'

function WidgetIframe({ title, source, height, deviceProp }) {
    const iframeContainer = useRef()
    const iframeRef = useRef()
    const [iframePaddingTop, setIframePaddingTop] = useState(0)
    const [deviceType] = useDeviceType()
    const isEnabled = deviceType === deviceProp

    useEffect(() => {
        if (!isEnabled || !iframeContainer?.current || !iframeRef.current || typeof window === 'undefined') {
            return
        }
        const { width: newWidth, height: newHeight } = iframeRef?.current?.getBoundingClientRect() || {}

        const newPadding = ((height || newHeight || 0) / newWidth) * 100
        if (newPadding !== iframePaddingTop) {
            setIframePaddingTop(newPadding)
        }
    }, [iframePaddingTop, height, isEnabled])

    return (
        <ConditionalWrapper
            Wrapper={AntiClsFrame}
            className="widget-iframe anti-cls-frame"
            condition={!!height}
            minHeightXs={height && `${height}px`}
            minHeightMd={height && `${height}px`}
            width="100%"
            marginXs="0 0 20px"
            marginMd="0">
            <div
                ref={iframeContainer}
                style={{
                    position: 'relative',
                    overflow: 'hidden',
                    width: '100%',
                    minHeight: '100%',
                    paddingTop: `${iframePaddingTop}%`
                }}>
                {isEnabled && (
                    <iframe
                        ref={iframeRef}
                        title={title}
                        src={source}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            width: '100%',
                            minHeight: height ? `${height}px` : 'initial',
                            height: 'auto'
                        }}
                    />
                )}
            </div>
        </ConditionalWrapper>
    )
}
WidgetIframe.propTypes = {
    title: PropTypes.string,
    source: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deviceProp: PropTypes.string
}
WidgetIframe.defaultProps = {
    title: undefined,
    source: undefined,
    height: undefined,
    deviceProp: 'mobile'
}

export default WidgetIframe
