import { createStyledComponent } from '@hmn/rtl-web-core/helpers/components/createStyledComponent'

const colors = {
    white: '#FFFFFF',
    darkBlueGradientStart: '#2B457F',
    darkBlueGradientEnd: '#346AAD',
    whiteTransparent: 'rgba(38, 77, 136, 0)',
    borderGrey: 'rgba(67, 67, 67, 0.2)',
    textWhite: '#FFFFFF',
    textDark: '#1e2a33',
    blackTransparent: 'rgba(0, 0, 0, 1)'
}

export const generatePartyStyles = (partiesList = [], animationDuration = 1000) => {
    const colorStyles = []

    // generate parties styles
    partiesList.forEach(party => {
        const partyStyle = {
            [`.izbori__card.is_${party?.slug}`]: {
                background:
                    `linear-gradient(180deg, ${party?.color} 0%, ` +
                    `${colors.whiteTransparent} 70.5%), ` +
                    `${colors.white}`
            }
        }
        colorStyles.push(partyStyle)
    })

    return colorStyles.reduce((combinedStyles, styles) => ({ ...combinedStyles, ...styles }), {})
}

const style = ({ theme, partiesList }) => ({
    '&.izbori': {
        display: 'flex',
        flexDirection: 'column',

        overflow: 'hidden',

        width: '100%',
        maxWidth: '1160px',
        padding: '0',
        margin: '0 auto',

        border: `1px solid ${colors.borderGrey}`,
        borderRadius: '3px',

        '.izbori': {
            '&__head': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '86px',
                background:
                    `linear-gradient(89.83deg, ${colors.darkBlueGradientStart} 0.15%, ` +
                    `${colors.darkBlueGradientEnd} 138.76%)`,

                'img.eu_izbori': {
                    width: '187px',
                    '@media (min-width: 768px)': {
                        width: '239px'
                    }
                },

                '&_info': {
                    textAlign: 'center',
                    '@media (min-width: 768px)': {
                        textAlign: 'right'
                    },

                    '> p': {
                        fontStyle: 'normal',
                        fontSize: '20px',
                        fontWeight: '700',
                        lineHeight: '25px',
                        letterSpacing: '-0.03em',
                        color: colors.textWhite,
                        '@media (min-width: 768px)': {
                            fontSize: '32px',
                            lineHeight: '17px',

                            '&.source': {
                                marginBottom: '-6px',
                                paddingTop: '4px'
                            }
                        },

                        span: {
                            display: 'none',
                            '@media (min-width: 768px)': {
                                display: 'inline',
                                fontSize: '14px',
                                fontWeight: '400',
                                marginLeft: '10px',
                                letterSpacing: '-0.01em'
                            }
                        }
                    },

                    '&.is_liste > p': {
                        marginLeft: '10px',
                        fontSize: '14px',
                        fontWeight: '400',
                        letterSpacing: '-0.01em',
                        '@media (min-width: 768px)': {
                            '> span': {
                                display: 'block'
                            }
                        },
                        span: {
                            marginLeft: '10px',
                            '&:not(.mob, .desk)': {
                                display: 'inline-block'
                            },
                            '&.mob': {
                                display: 'inline-block',
                                '@media (min-width: 768px)': {
                                    display: 'none'
                                }
                            },
                            '&.desk': {
                                display: 'none',
                                '@media (min-width: 768px)': {
                                    display: 'block'
                                }
                            }
                        }
                    }
                },

                '@media (min-width: 768px)': {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    height: '81px',
                    padding: '0 60px 0 30px'
                }
            },

            '&__parties-foot': {
                textAlign: 'right',
                p: {
                    display: 'inline',
                    marginLeft: '10px',
                    fontSize: '14px',
                    fontWeight: '400',
                    letterSpacing: '-0.01em',
                    color: colors.textDark
                },
                span: {
                    marginRight: '10px'
                },
                '@media (min-width: 768px)': {
                    display: 'none',
                    justifyContent: 'space-between',
                    margin: '14px 14px 0'
                }
            },

            '&__parties-foot-meta': {},

            '&__parties-cards': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'flex-start',
                flexWrap: 'wrap',
                padding: '8px',
                overflowX: 'auto', // Allow horizontal scrolling
                borderRadius: '3px',
                WebkitOverflowScrolling: 'touch', // Smooth scrolling on iOS devices
                scrollSnapType: 'x mandatory',

                '@media (min-width: 768px)': {
                    flexWrap: 'nowrap',
                    padding: '8px 0',

                    '&:has(> .izbori__card:nth-of-type(7))': {
                        paddingBottom: '37px'
                    }
                },

                '&:not(:has(> .izbori__card:nth-of-type(5)))': {
                    justifyContent: 'space-evenly',

                    '& .izbori__card:not(.is_candidate)': {
                        margin: '10px'
                    },

                    '@media (min-width: 768px)': {
                        justifyContent: 'center',

                        '& .izbori__card:not(.is_candidate)': {
                            margin: '20px'
                        }
                    }
                }
            }
        }
    },

    '& .swiper-slide': {
        width: '130px',
        '> div.izbori__card.is_candidate': {
            width: '100%',
            margin: '0',
            '> img': {
                width: '100%'
            }
        },

        '@media (min-width: 768px)': {
            width: '180px',
            '&:first-of-type': {
                marginLeft: '10px',
                '> div.izbori__card.is_candidate': {}
            },

            '&:last-of-type': {
                marginRight: '10px',
                '> div.izbori__card.is_candidate': {}
            }
        }
    },

    '& .swiper-button-prev, & .swiper-button-next': {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        backgroundColor: colors.blackTransparent,
        '&::after': {
            fontSize: '16px',
            fontWeight: '900',
            color: colors.textWhite
        }
    },

    // test styles for broken images on global level
    // try to reuse
    '& .img-err': {
        position: 'relative',
        display: 'inline-block',
        overflow: 'hidden',

        '> img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'relative',

            color: '#1e2a33',
            fontSize: '14px',
            fontWeight: '700',
            textAlign: 'center'
        },

        '.imgErr, .imgError_alt': {
            display: 'none'
        },

        '.imgErr + .imgError_alt': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            padding: '0 10px',

            color: colors.textDark,
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '16px',
            textAlign: 'center',
            fontStyle: 'normal',
            textTransform: 'capitalize',

            backgroundColor: '#fff',

            '@media (min-width: 768px)': {
                fontSize: '18px',
                lineHeight: '20px'
            }
        }
    },

    // Merge party styles into the layout
    ...generatePartyStyles(partiesList)
})

export default createStyledComponent({
    tag: 'div',
    styles: style
})
