import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import HtmlContent from '@hmn/rtl-net-ui/components/HtmlContent/HtmlContent.component'

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetRichtext({ richtext, position }) {
    return (
        <Cell position={position}>
            <HtmlContent data={richtext} />
        </Cell>
    )
}
WidgetRichtext.propTypes = {
    position: PropTypes.shape({}),
    richtext: PropTypes.arrayOf(PropTypes.shape({}))
}
WidgetRichtext.defaultProps = {
    position: {},
    richtext: []
}

export default WidgetRichtext
