/* eslint-disable quote-props, no-dupe-keys, max-len, @typescript-eslint/naming-convention */

// import { back } from '@hmn/rtl-web-core/hooks'
import { createStyledComponent } from '../../helpers/components/createStyledComponent'
import { toRem, toRems } from '../../helpers/theme'

type StyleProps = {
    theme?: any
    isWide?: boolean
    itemWidthMd: number
    itemWidthSm: number
    gutterMd: number
    gutterSm: number
    isIzbori?: boolean
}
export const voyoSliderBorderWidth = 7
export const voyoSliderMaxHeightSm = 415
export const voyoSliderMaxWidthSm = 320
export const voyoSliderMaxHeightMd = 250
export const voyoSliderMaxWidthMd = 1190

const ssrRatioMd = 0.96
const ssrRatioSm = 1

const ratioSm = voyoSliderMaxHeightSm / voyoSliderMaxWidthSm
export const voyoSliderRatioMd = voyoSliderMaxHeightMd / voyoSliderMaxWidthMd
const styles = ({ theme, isWide, itemWidthMd, itemWidthSm, gutterMd, gutterSm, isIzbori }: StyleProps) => {
    const config = {
        sm: {
            swiper: {
                width: '100%',
                margin: 0
            }
        },
        md: {
            swiper: {
                width: '100%',
                margin: 0
            }
        }
    }
    const border = `${toRem(voyoSliderBorderWidth)} solid ${!isIzbori ? theme.colors.voyo : 'transparent'}`

    return {
        margin: 'auto',
        width: '100%',
        height: 'auto',
        position: 'relative',
        maxWidth: toRem(voyoSliderMaxWidthSm),
        maxHeight: toRem(voyoSliderMaxHeightSm),
        backgroundColor: theme.colors.white,
        boxSizing: 'border-box',
        overflow: 'hidden',
        [theme.breakpoints.up('md')]:
            (isWide && {
                width: '100%',
                maxWidth: toRem(voyoSliderMaxWidthMd),
                maxHeight: toRem(voyoSliderMaxHeightMd)
            }) ||
            {},
        '.VoyoSlider': {
            '&_container': {
                '&::before': {
                    content: '""',
                    width: '1px',
                    marginLeft: '-1px',
                    float: 'left',
                    height: 0,
                    paddingTop: `calc(${ratioSm} * 100%)`
                },
                '&::after': {
                    content: '""',
                    display: 'table',
                    clear: 'both'
                },
                position: 'relative',
                height: '100%',
                [theme.breakpoints.up('md')]:
                    (isWide && {
                        '&::before': {
                            content: '""',
                            width: '1px',
                            marginLeft: '-1px',
                            float: 'left',
                            height: 0,
                            paddingTop: `calc(${voyoSliderRatioMd} * 100%)`
                        }
                    }) ||
                    {}
            },
            '&_swiper': {
                borderTop: border,
                position: 'absolute',
                boxSizing: 'border-box',
                height: '100%',
                width: config.sm.swiper.width,
                margin: config.sm.swiper.margin,
                [theme.breakpoints.up('md')]:
                    (isWide && {
                        width: config.md.swiper.width,
                        margin: config.md.swiper.margin,
                        border
                    }) ||
                    {},
                '&.ssr': {
                    width: '300%'
                }
            },
            '&_item': {
                '&.ssr': {
                    width: itemWidthSm * ssrRatioSm + 'px',
                    marginRight: gutterSm * ssrRatioSm + 'px',
                    marginBottom: gutterSm * ssrRatioSm + 'px',
                    display: 'inline-block',
                    [theme.breakpoints.up('md')]:
                        (isWide && {
                            width: itemWidthMd * ssrRatioMd + 'px',
                            marginRight: gutterMd * ssrRatioMd + 'px'
                        }) ||
                        {}
                },
                width: `${(itemWidthSm / voyoSliderMaxWidthSm) * 100}%`,
                height: 'auto',
                '&_hidden': {
                    display: 'none'
                },
                '&_inserted': {
                    display: (!isWide && 'none') || 'block',
                    [theme.breakpoints.down('sm')]: {
                        display: 'none'
                    }
                },
                '&_cta': {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    '& > *': {
                        flex: '1 1 auto'
                    },
                    [theme.breakpoints.up('md')]:
                        (isWide && {
                            display: 'none'
                        }) ||
                        {}
                }
            },
            '&_slider': {
                overflow: 'hidden',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                position: 'absolute',
                top: '40%',
                zIndex: 10
            },
            '&_pagination': {
                display: 'none',
                [theme.breakpoints.up('md')]:
                    (isWide && {
                        display: 'flex',
                        position: 'absolute',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        top: 0,
                        left: 0,
                        zIndex: 20,
                        width: '100%'
                    }) ||
                    {},
                '&_button': {
                    width: toRem(40),
                    height: toRem(40),
                    fontSize: toRem(30),
                    lineHeight: toRem(30),
                    backgroundColor: 'rgba(0,0,0,.5)',
                    padding: toRem(5),
                    path: {
                        fill: theme.colors.white
                    }
                }
            }
        },
        '.swiper-button-disabled': {
            opacity: 0,
            pointerEvents: 'none'
        },

        // IZBORI2024: custom styles
        '&#slider_e0669948-ed2b-11ee-88d9-faa67538f252, &#slider_4241fbfe-ed30-11ee-aa5d-7ecc6c482c09': {
            '.VoyoSlider': {
                '&_container': {
                    '&::before': {
                        [theme.breakpoints.down('sm')]: {
                            paddingTop: `calc(${ratioSm * 100}% - 50px)`
                        }
                    },
                    '&_swiper, > .swiper': {
                        borderColor: 'transparent !important'
                    }
                },
                '&_item_cta': {
                    display: 'none'
                },
                '&_pagination': {
                    top: '50%',
                    height: 0,

                    '> span': {
                        height: '40px',
                        translate: '0 -50%',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        marginInline: '20px'
                    },

                    svg: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)'
                    }
                }
            }
        }
    }
}

export default createStyledComponent({
    styles,
    tag: 'div'
})

const ctaStyles = ({ theme }: StyleProps) => ({
    width: '100%',
    background: theme.colors.voyo,
    height: toRem(50),
    padding: toRem(5),
    '.VoyoCta': {
        '&_voyo,&_message': {
            width: 'auto'
        },
        '&_voyo': {
            height: toRem(18),
            margin: toRems([5, 18])
        },
        '&_message': {
            height: toRem(40),
            width: 'auto'
        },
        '&_custom-message': {
            display: 'flex',
            height: '38px',
            width: '144px',
            marginRight: '-5px',

            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',

            backgroundColor: theme.colors.voyoCtaBg,

            strong: {
                color: theme.colors.white,

                fontSize: toRem(15),
                fontWeight: 700,
                lineHeight: toRem(18),
                textTransform: 'uppercase',
                textWrap: 'balance',

                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '2'
            }
        }
    }
})

export const VoyoCtaStyled = createStyledComponent({
    styles: ctaStyles,
    tag: 'div'
})
