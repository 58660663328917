import React, {  forwardRef, PropsWithChildren,  FC, ForwardedRef, RefObject  } from 'react'

const ConditionalWrapper = forwardRef<
    HTMLElement,
    PropsWithChildren<{
        condition: boolean
        // @TODO: change usage from Wrapper to wrapper
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Wrapper: FC<
            PropsWithChildren<
                unknown & {
                    ref: ForwardedRef<HTMLElement> | RefObject<HTMLElement>
                }
            >
        >,
        [key: string]: unknown
    }>
>(
    (
        {
            condition,
            // @TODO: change usage from Wrapper to wrapper
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Wrapper,
            children,
            ...rest
        },
        ref
    ) => {
        if (!Wrapper || !condition) return <>{children}</>

        return (
            <Wrapper ref={ref} {...rest}>
                {children}
            </Wrapper>
        )
    }
)

export default ConditionalWrapper
