import { useRef } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Button } from '../../../Button'
import styles, { Divider } from './Listing.style'

const ListingStyled = styled.section(props => ({ ...styles(props) }))
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Listing({ children, className, sitemap, ...rest }) {
    const uid = useUIDSeed()
    const levelRef = useRef(0)

    if (!sitemap?.length) {
        return null
    }
    return (
        <ListingStyled className={className} {...rest}>
            {sitemap.map((item, index) => {
                const level = parseInt(item.tree_level, 10)
                let comparedToPrev
                if (level > levelRef.current) {
                    comparedToPrev = 'smaller'
                } else if (level < levelRef.current) {
                    comparedToPrev = 'greater'
                } else {
                    comparedToPrev = 'equal'
                }
                levelRef.current = level
                return (
                    <div className="list" key={uid(item.id || index)}>
                        {!parseInt(item.tree_level, 10) ? (
                            <>
                                {comparedToPrev !== 'equal' && (
                                    <Divider comparedToPrev={comparedToPrev} level={level} />
                                )}
                                <Button className="list_category" simple href={`${item.uri}`} hrefAs={`${item.uri}`}>
                                    {item.title}
                                </Button>
                            </>
                        ) : (
                            <>
                                {comparedToPrev !== 'equal' && (
                                    <Divider comparedToPrev={comparedToPrev} level={level} />
                                )}
                                <ul className={`list_subcategory_level${item.tree_level}`}>
                                    <li>
                                        <Button
                                            className="list_subcategory_button"
                                            simple
                                            href={`${item.uri}`}
                                            hrefAs={`${item.uri}`}>
                                            {item.title}
                                        </Button>
                                    </li>
                                </ul>
                            </>
                        )}
                    </div>
                )
            })}
        </ListingStyled>
    )
}

Listing.propTypes = {
    className: PropTypes.string,
    sitemap: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
}
Listing.defaultProps = {
    className: undefined,
    sitemap: undefined
}

export default withErrorBoundary(Listing, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Listing]: ', error, componentStack)
    }
})
