import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Button, netButtonVariants } from '../../../Button'
import {
    Graphic,
    netGraphicFillVariants,
    netGraphicVariants,
    Ribbon,
    ribbonVariants
} from '../../../Decoration/components'
import { Icon, NewsletterIcon } from '../../../Icon'
import styles from './Newsletter.net.style'

const NewsletterStyled = styled.div(props => ({ ...styles(props) }))

function Newsletter({ className, variant, ...rest }) {
    const [email, setEmail] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [message, setMessage] = useState('')
    const emailInputRef = useRef()

    const handleSubmit = event => {
        event.preventDefault()
        setIsSubmitting(true)

        if (!window.exponea) {
            setMessage('Dogodila se greška! Pokušaj osvježiti stranicu.')
            setIsSubmitting(false)
            return
        }

        if (!email) {
            setMessage('Upiši svoju e-mail adresu')
            setIsSubmitting(false)
            emailInputRef.current.focus()
            return
        }

        const emailRegTest = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.']\w+)*$/
        if (!emailRegTest.test(email)) {
            setMessage('Upiši ispravnu e-mail adresu')
            setIsSubmitting(false)
            emailInputRef.current.focus()
            return
        }

        window.exponea.identify(
            { email_id: email },
            { email, net_hr: true, marketing_consent: 'Yes' },
            () => {
                window.exponea.track('consent', {
                    action: 'accept',
                    category: 'net.hr',
                    valid_until: 'unlimited',
                    email
                })

                setMessage('Uspjeh! Hvala na pretplati!')
                setEmail('')
                setIsSubmitting(false)
            },
            error => {
                setMessage(`Dogodila se greška! Pokušaj osvježiti stranicu. Greška: ${error.message}`)
                setIsSubmitting(false)
            },
            false
        )
    }

    return (
        <NewsletterStyled className={className} variant={variant} {...rest}>
            <div className="newsletter_text" id="newsletter_text">
                <div className="newsletter_text_title" id="newsletter_text_title">
                    Primaj net.hr newsletter
                </div>
                <div className="newsletter_text_content" id="newsletter_text_content">
                    Novosti direktno u tvoj inbox.
                    {variant !== 'alpha' && <br className="newsletter_text_br" />} Ne propusti ništa, nikad.
                </div>
            </div>

            <div className="newsletter_form">
                <form className="newsletter_input" onSubmit={handleSubmit}>
                    <div className="newsletter_input_fieldWrapper">
                        <input
                            ref={emailInputRef}
                            className="newsletter_input_field"
                            placeholder="Upiši svoju email adresu..."
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <Button
                        type="submit"
                        className="newsletter_input_button"
                        id="newsletter_input_button"
                        variant={netButtonVariants.BETA}
                        disabled={isSubmitting}
                        heightMd={50}
                        heightXs={43}>
                        Prijavi se
                    </Button>
                </form>

                <div className={`newsletter_form_status ${message.includes('Uspješno') ? 'success' : 'fail'}`}>
                    {message}
                </div>
            </div>
            <Ribbon
                className="newsletter_ribbon"
                id="newsletter_ribbon"
                variant={ribbonVariants.BETA}
                iconComponent={
                    <Icon
                        icon={NewsletterIcon}
                        id="newsletter_ribbon_icon"
                        className="newsletter_ribbon_icon"
                        viewBox="0 0 48 48"
                    />
                }
            />
            <Graphic
                className="newsletter_decoration"
                variant={netGraphicVariants.ALPHA}
                fillVariant={netGraphicFillVariants.ALTERNATIVE}
                heightMd={10}
                heightXs={10}
                widthMd="100%"
                widthXs="100%"
            />
        </NewsletterStyled>
    )
}

const newsletterVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma'
})

Newsletter.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(newsletterVariants)])
}

Newsletter.defaultProps = {
    className: undefined,
    variant: newsletterVariants.ALPHA
}

export { newsletterVariants }

export default Newsletter
