/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import styled from '@emotion/styled'
import PropTypes, { InferProps } from 'prop-types'
import React from 'react'

import { ConditionalWrapper } from '../../../ConditionalWrapper'
import VoyoCardStyled from './Card.style'

export const voyoCardImageHeight = 420
export const voyoCardImageWidth = 300
export const voyoCardImageRatio = voyoCardImageWidth / voyoCardImageHeight

const A = styled.a()
const VoyoCard = ({
    buttonHref,
    title,
    imageId,
    imageUpdatedAt,
    onClick,
    imageView,
    ImageComponent
}: InferProps<typeof VoyoCard.propTypes>) => {
    if (!imageId || !buttonHref || !ImageComponent) {
        return null
    }

    return (
        <VoyoCardStyled onClick={onClick}>
            <ConditionalWrapper
                condition={!!buttonHref}
                Wrapper={A as any}
                href={buttonHref}
                // as={buttonHref}
                // isExternal
                target="_blank"
                className={imageView ? '' : 'fullHeight'}
                title={buttonHref}>
                <ImageComponent
                    image={imageId}
                    ratio={voyoCardImageRatio}
                    width={voyoCardImageWidth}
                    height={voyoCardImageHeight}
                    alt={title}
                    updatedAt={imageUpdatedAt}
                    original
                    lazyLoad={false}
                />
            </ConditionalWrapper>
        </VoyoCardStyled>
    )
}

VoyoCard.propTypes = {
    ImageComponent: PropTypes.elementType,
    buttonHref: PropTypes.string,
    title: PropTypes.string,
    imageId: PropTypes.string,
    imageUpdatedAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    onClick: PropTypes.func,
    imageView: PropTypes.bool
}

VoyoCard.defaultProps = {
    ImageComponent: undefined,
    buttonHref: undefined,
    title: undefined,
    imageId: undefined,
    imageLabel: undefined,
    imageUpdatedAt: undefined,
    onClick: undefined,
    imageView: false
}

export default VoyoCard
