import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { getListElastic } from '@hmn/rtl-web-core/hooks'

import { Zodiac, zodiacVariants } from '@hmn/rtl-net-ui/components/Fun/components'

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetHoroskop({ position, signList }) {
    return (
        <Cell position={position}>
            <div>
                <Zodiac variant={zodiacVariants.ALPHA} signList={signList} />
            </div>
        </Cell>
    )
}

export async function getData() {
    const { data: signList } = await getListElastic('horoscope', undefined, undefined, { method: 'GET' })

    return {
        signList
    }
}

WidgetHoroskop.propTypes = {
    position: PropTypes.shape({}),
    signList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}
WidgetHoroskop.defaultProps = {
    position: {}
}

export default WidgetHoroskop
