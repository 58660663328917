import { useUIDSeed } from 'react-uid'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import aboveTheFoldItemsNumberHelper from '@hmn/rtl-web-core/helpers/aboveTheFoldItemsNumberHelper'

import { ArticleCard, articleCardContentMarkerVariants } from '@hmn/rtl-net-ui/components/Article/components'
import { VideoPhotoBlock } from '@hmn/rtl-net-ui/components/VideoPhotoBlock'
import { NetLuna as Luna } from '@hmn/rtl-net-ui/controllers/NetLuna'

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetPhotoVideo({ items, title, position }) {
    const uid = useUIDSeed()
    const itemCount = items?.length || 1
    const { y, h, w } = position

    if (items.length !== 5) {
        return null
    }

    const numberOfItemsInViewPort = aboveTheFoldItemsNumberHelper(itemCount, w, y, h)

    return (
        <Cell position={position}>
            <Luna theme="netDark">
                <VideoPhotoBlock title={title}>
                    {items?.map((item, index) => (
                        <ArticleCard
                            item={item}
                            key={uid(item?.id || index)}
                            contentMarkerVariant={
                                item.type === 'gallery'
                                    ? articleCardContentMarkerVariants.PHOTO
                                    : articleCardContentMarkerVariants.VIDEO
                            }
                            imageLazyLoad={index >= numberOfItemsInViewPort}
                            imagePreload={index < numberOfItemsInViewPort}
                        />
                    ))}
                </VideoPhotoBlock>
            </Luna>
        </Cell>
    )
}

WidgetPhotoVideo.propTypes = {
    title: PropTypes.string,
    position: PropTypes.shape({
        y: PropTypes.number,
        w: PropTypes.number,
        h: PropTypes.number
    }),
    items: PropTypes.arrayOf(PropTypes.shape({}))
}
WidgetPhotoVideo.defaultProps = {
    title: undefined,
    position: undefined,
    items: []
}
export default WidgetPhotoVideo
