import { toRem, toRems } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    width: '100%',
    marginBottom: toRem(10),
    backgroundColor: theme.colors.breaking.background.default,
    '& .netBreaking_inner': {
        position: 'relative',
        backgroundColor: theme.colors.breaking.background.default,
        marginLeft: toRem(-15),
        marginRight: toRem(-15)
    },
    '& .netBreaking_list': {
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridRowGap: toRem(15),
            gridColumnGap: toRem(15),
            alignItems: 'center'
        }
    },
    '& .netBreaking_item': {
        '&.isFirst': {
            gridColumn: '1/4',
            padding: 0
        },
        padding: toRems([0, 0, 15, 30]),
        [theme.breakpoints.down('sm')]: {
            padding: toRems([0, 15, 15, 15]),
            '&:nth-of-type(1)': {
                marginBottom: toRem(15)
            }
        }
    }
})

export default style
