import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { Linker } from '@hmn/rtl-net-ui/components/Linker'

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetLinker({ style, position, ...rest }) {
    return (
        <Cell position={position}>
            <Linker key={position} />
        </Cell>
    )
}
WidgetLinker.propTypes = {
    position: PropTypes.shape({
        w: PropTypes.number
    }),
    style: PropTypes.string
}
WidgetLinker.defaultProps = {
    position: {},
    style: ''
}

export default WidgetLinker
