import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { Divider } from '@hmn/rtl-net-ui/components/Divider'

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetDivider({ style, position, ...rest }) {
    return (
        <Cell position={position}>
            <Divider />
        </Cell>
    )
}
WidgetDivider.propTypes = {
    position: PropTypes.shape({}),
    style: PropTypes.string
}
WidgetDivider.defaultProps = {
    position: {},
    style: ''
}
export default WidgetDivider
