import { useUIDSeed } from 'react-uid'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import aboveTheFoldItemsNumberHelper from '@hmn/rtl-web-core/helpers/aboveTheFoldItemsNumberHelper'

import { ArticleCard, ArticleSpecial, articleSpecialVariants } from '@hmn/rtl-net-ui/components/Article/components'
import { Breaking } from '@hmn/rtl-net-ui/components/Breaking'
import { imageRatioVariants } from '@hmn/rtl-net-ui/components/Image'

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetBreaking({ items, title, label, position }) {
    const uid = useUIDSeed()
    const itemCount = items?.length || 1
    const { y, h, w } = position

    if (!items?.length) {
        return null
    }

    const numberOfItemsInViewPort = aboveTheFoldItemsNumberHelper(itemCount, w, y, h)

    return (
        <Cell position={position}>
            <Breaking>
                {items?.map(({ key, item, variant }, index) =>
                    index === 0 ? (
                        <ArticleSpecial
                            item={item}
                            key={uid(item?.id || index)}
                            variant={articleSpecialVariants.BETA}
                            subtitle={!title && item?.subtitle}
                            label={label}
                            title={title || item?.title || item?.name}
                            imageLazyLoad={index >= numberOfItemsInViewPort}
                            imagePreload={index < numberOfItemsInViewPort}
                        />
                    ) : (
                        <ArticleCard
                            imageVariation={imageRatioVariants.CUSTOM_SQUARE}
                            item={item}
                            key={uid(item?.id || index)}
                            imageLazyLoad={index >= numberOfItemsInViewPort}
                            imagePreload={index < numberOfItemsInViewPort}
                        />
                    )
                )}
            </Breaking>
        </Cell>
    )
}

WidgetBreaking.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    position: PropTypes.shape({
        y: PropTypes.number,
        w: PropTypes.number,
        h: PropTypes.number
    }),
    items: PropTypes.arrayOf(PropTypes.shape({}))
}
WidgetBreaking.defaultProps = {
    title: undefined,
    label: undefined,
    position: undefined,
    items: []
}
export default WidgetBreaking
