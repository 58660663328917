import { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import SwiperCore, { Autoplay, Navigation } from 'swiper'

import { AntiClsFrame } from '@hmn/rtl-web-core/components/AntiClsFrame'
import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { RenderInView } from '@hmn/rtl-web-core/components/RenderInView'
import { useData, useGTM } from '@hmn/rtl-web-core/hooks'
import { returnEmptyOn4xx } from '@hmn/rtl-web-core/next/data-fetching/handle-errors'

import ProductGallery from './ProductGallery.component'

SwiperCore.use([Autoplay, Navigation])

const parseLoadedData = data => ({
    title: data?.title,
    items: data?.extended_attributes?.product_galleries_products,
    showLogo: !data?.extended_attributes?.product_galleries_show_title,
    logo: data?.image,
    titleUrl: data?.extended_attributes?.product_galleries_url,
    disclaimer: data?.extended_attributes?.product_galleries_disclaimer
})

const listalicaProizvodaSourceVariants = Object.freeze({
    ITEMS: 'items', // uses regular props, not response
    NET_WEBSHOP: 'net-webshop',
    SPAR: 'spar',
    KONZUM: 'konzum',
    T_HT: 't-ht'
})

const getProductGalleryId = variant => {
    switch (variant) {
        case listalicaProizvodaSourceVariants.NET_WEBSHOP:
            return '85d25420-be00-11eb-af0e-6ef79b5c6087'
        case listalicaProizvodaSourceVariants.KONZUM:
            return '5234efd4-ce94-11eb-a4e9-560801fed869'
        case listalicaProizvodaSourceVariants.SPAR:
            return 'b5b7334e-2765-11ec-bf01-56fed9847f00'
        case listalicaProizvodaSourceVariants.T_HT:
            return '937b1ce8-7546-11ed-997d-4e5716b11154'
        case listalicaProizvodaSourceVariants.ITEMS:
        default:
            return null
    }
}

function ListalicaProizvodaAntiClsFrame({ children }) {
    return (
        <AntiClsFrame minHeightXs={630} minHeightMd={630}>
            {children}
        </AntiClsFrame>
    )
}

function ListalicaProizvoda({
    title: title0,
    showLogo: showLogo0,
    logoImage,
    items: items0,
    titleUrl: titleUrl0,
    disclaimer: disclaimer0,
    className,
    isWidget,
    sourceVariant
}) {
    const galleryId = getProductGalleryId(sourceVariant)

    const { data: loadedData } = useData({
        resource: `entity/${galleryId}`,
        enabled: !!galleryId && sourceVariant !== listalicaProizvodaSourceVariants.ITEMS,
        options: { preview: true },
        errorHandlers: returnEmptyOn4xx
    })

    /**
     * Depending on sourceVariant it returns loadedData with props where loadedData is missing
     *  or just the props when sourceVariant is not provided (not is ITEMS).
     * This approach uses functional style where the data is not mutated.
     */
    const { title, items, titleUrl, disclaimer, logo, showLogo } = useMemo(() => {
        if (typeof loadedData !== 'undefined') {
            const parsedData = parseLoadedData(loadedData)

            return {
                title: parsedData?.title || title0,
                items: parsedData?.items || items0,
                titleUrl: parsedData?.titleUrl || titleUrl0,
                disclaimer: parsedData?.disclaimer || disclaimer0,
                logo: logoImage || parsedData?.logo,
                showLogo: parsedData?.showLogo || showLogo0
            }
        }

        return {
            title: title0,
            items: items0,
            titleUrl: titleUrl0,
            disclaimer: disclaimer0,
            logo: logoImage,
            showLogo: showLogo0
        }
    }, [loadedData, title0, items0, titleUrl0, disclaimer0, logoImage, showLogo0])

    const { data: image = logo } = useData({
        resource: `image/${logo?.id}`,
        enabled: !!logo?.id && !logoImage?.id,
        errorHandlers: returnEmptyOn4xx
    })

    const { sendDataToGTM } = useGTM()
    const gtmViewData = useMemo(
        () => ({
            event: 'gtm.View',
            eventCategory: isWidget ? 'Listalica - naslovnica' : 'Listalica - članak',
            eventAction: `View ${title}`,
            eventLabel: `${title} - ${titleUrl}`
        }),
        [title, titleUrl, isWidget]
    )
    const onInViewChange = useCallback(
        (inView = false) => {
            if (!inView) {
                return
            }
            sendDataToGTM(gtmViewData)
        },
        [gtmViewData, sendDataToGTM]
    )

    const gtmClickData = useMemo(
        () => ({
            event: 'gtm.Click',
            eventCategory: isWidget ? 'Listalica - naslovnica' : 'Listalica - članak',
            eventAction: `Click ${title}`,
            eventLabel: `${title} - ${titleUrl}`
        }),
        [title, titleUrl, isWidget]
    )

    const onItemClick = useCallback(() => {
        sendDataToGTM(gtmClickData)
    })

    if (!items) {
        return null
    }

    const currentDate = dayjs()
    const activeItems = items.filter(item =>
        item?.schedule ? currentDate.isAfter(item.show_from) && currentDate.isBefore(item.show_until) : true
    )

    return (
        <RenderInView>
            <ProductGallery
                title={title}
                showLogo={showLogo}
                logoImage={image}
                items={activeItems}
                titleUrl={titleUrl}
                disclaimer={disclaimer}
                className={className}
                onInViewChange={onInViewChange}
                onItemClick={onItemClick}
            />
        </RenderInView>
    )
}
ListalicaProizvoda.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    showLogo: PropTypes.bool,
    logoImage: PropTypes.shape({
        id: PropTypes.string
    }),
    items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    titleUrl: PropTypes.string,
    isWidget: PropTypes.bool,
    disclaimer: PropTypes.string,
    sourceVariant: PropTypes.oneOf([...Object.values(listalicaProizvodaSourceVariants)])
}

ListalicaProizvoda.defaultProps = {
    className: undefined,
    title: 'Net.hr webshop',
    showLogo: undefined,
    logoImage: undefined,
    items: undefined,
    titleUrl: undefined,
    isWidget: false,
    disclaimer: undefined,
    sourceVariant: listalicaProizvodaSourceVariants.ITEMS
}

export { listalicaProizvodaSourceVariants, ListalicaProizvodaAntiClsFrame }

export default withErrorBoundary(ListalicaProizvoda, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ListalicaProizvoda]: ', error, componentStack)
    }
})
