import React, { useMemo } from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

function RssAutodiscovery({ useDefaultRss, sitemap, defaultRssUrl, siteName, titleSeparator }) {
    const { categoryRss, mainRss } = useMemo(
        () => ({
            categoryRss:
                sitemap?.node?.href &&
                `${process.env.NEXT_PUBLIC_APP_ROOT_NET}/feed/${sitemap.node.href.split('/')?.pop()}`,
            mainRss: !!defaultRssUrl && `${process.env.NEXT_PUBLIC_APP_ROOT_NET}${defaultRssUrl}`
        }),
        [sitemap, defaultRssUrl]
    )
    return (
        <Head>
            {categoryRss && (
                <link
                    rel="alternate"
                    type="application/rss+xml"
                    title={`${
                        sitemap.node.title
                            ? `${siteName && `${siteName} ${titleSeparator} `}Rubrika ${sitemap.node.title} `
                            : ''
                    }RSS`}
                    href={categoryRss}
                />
            )}
            {useDefaultRss && mainRss && (
                <link
                    rel="alternate"
                    type="application/rss+xml"
                    title={`${siteName && `${siteName} ${titleSeparator} `}Glavni RSS feed`}
                    href={mainRss}
                />
            )}
        </Head>
    )
}

RssAutodiscovery.propTypes = {
    useDefaultRss: PropTypes.bool,
    sitemap: PropTypes.oneOfType([() => null, PropTypes.object]),
    defaultRssUrl: PropTypes.string,
    siteName: PropTypes.string,
    titleSeparator: PropTypes.string
}

RssAutodiscovery.defaultProps = {
    useDefaultRss: true,
    sitemap: undefined,
    defaultRssUrl: '/feed',
    siteName: 'Net.hr',
    titleSeparator: '|'
}
export default withErrorBoundary(RssAutodiscovery, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[RssAutodiscovery]: ', error, componentStack)
    }
})
