import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { getWidgetZoneName } from '@hmn/rtl-web-core/helpers/ads'

import AdSlot from '@hmn/rtl-net-ui/components/Ad/NetSlot.component'
import { toRems } from '@hmn/rtl-net-ui/helpers'

import { Cell } from '../LayoutBuilder.style'

const adPerColWidth = 100
function WidgetBanner({ position, adPosition, deviceProp }) {
    // console.log({ position, adPosition })
    const zoneName = useMemo(
        () =>
            deviceProp
                ? { [deviceProp]: getWidgetZoneName(adPosition?.[deviceProp]) }
                : { desktop: getWidgetZoneName(adPosition?.desktop), mobile: getWidgetZoneName(adPosition?.mobile) },
        [adPosition, deviceProp]
    )
    return (
        <Cell
            position={position}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'stretch',
                padding: position?.w === 12 ? 0 : toRems([5, 0, 10, 15]),
                margin: toRems(position?.w === 12 ? [0, -15] : [0, 0, 15])
            }}>
            <div
                style={{
                    padding: 0,
                    margin: 0,
                    position: 'relative',
                    width: '100%',
                    height: '100%'
                }}>
                <AdSlot
                    isWidgetBanner
                    position={zoneName}
                    maxAdWidth={adPerColWidth * (position?.w || 12)}
                    {...(position && position?.w <= position?.h
                        ? {
                              isSliding: true,
                              slidingProps: {
                                  offsetTop: 70,
                                  contentWidth: 300
                              }
                          }
                        : {})}
                />
            </div>
        </Cell>
    )
}
WidgetBanner.propTypes = {
    position: PropTypes.shape({ w: PropTypes.number, h: PropTypes.number }),
    adPosition: PropTypes.shape({
        desktop: PropTypes.shape({}),
        mobile: PropTypes.shape({})
    }),
    bannerHeader: PropTypes.string,
    bannerBody: PropTypes.string,
    bannerDimension: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    deviceProp: PropTypes.string
}
WidgetBanner.defaultProps = {
    position: {},
    adPosition: undefined,
    bannerHeader: undefined,
    bannerBody: undefined,
    bannerDimension: undefined,
    deviceProp: undefined
}

export default WidgetBanner
