import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import styles from './Divider.net.style'

const DividerStyled = styled.div(props => ({ ...styles(props) }))

function Divider({ isEmpty, className, ...rest }) {
    return <DividerStyled isEmpty={isEmpty} className={className} {...rest} />
}

Divider.propTypes = {
    className: PropTypes.string,
    isEmpty: PropTypes.bool
}

Divider.defaultProps = {
    className: undefined,
    isEmpty: false
}

export default withErrorBoundary(Divider, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Divider]: ', error, componentStack)
    }
})
