import { toRem } from '../../../../helpers/theme'

const style = ({ theme, variant }) => {
    const config = {
        sm: {
            variant: {
                alpha: {
                    backgroundColor: theme.colors.background.accent
                },
                beta: {
                    backgroundColor: theme.colors.background.accent
                },
                gamma: {
                    backgroundColor: theme.colors.background.contrast,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }
            },
            text: {
                title: {
                    variant: {
                        alpha: {
                            fontSize: toRem(24)
                        },

                        beta: {
                            fontSize: toRem(20)
                        },

                        gamma: {
                            fontSize: toRem(16)
                        }
                    }
                },
                content: {
                    variant: {
                        alpha: {
                            fontSize: toRem(16)
                        },
                        beta: {
                            fontSize: toRem(16)
                        },
                        gamma: {
                            fontSize: toRem(14),
                            display: 'none'
                        }
                    }
                }
            },
            ribbon: {
                variant: {
                    alpha: {
                        right: toRem(-10),
                        top: toRem(20),
                        marginTop: toRem(15),
                        marginLeft: toRem(15)
                    },
                    beta: {
                        right: toRem(-10),
                        top: toRem(20),
                        marginTop: toRem(15),
                        marginLeft: toRem(15)
                    },
                    gamma: {
                        right: toRem(-10),
                        top: toRem(20),
                        marginTop: toRem(15),
                        marginLeft: toRem(15),
                        display: 'none'
                    }
                }
            },
            button: {
                variant: {
                    alpha: {
                        fontSize: toRem(16),
                        width: toRem(130),
                        height: toRem(50),
                        marginTop: toRem(28)
                    },

                    beta: {
                        fontSize: toRem(16),
                        width: toRem(130),
                        height: toRem(50),
                        marginTop: toRem(28)
                    },

                    gamma: {
                        fontSize: toRem(14),
                        width: toRem(100),
                        height: toRem(35),
                        marginTop: 0,
                        marginLeft: toRem(30)
                    }
                }
            },
            graphic: {
                variant: {
                    gamma: {
                        display: 'none'
                    }
                }
            }
        },
        md: {
            variant: {
                alpha: {
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingRight: toRem(80),
                    button: {
                        marginLeft: '7%',
                        marginTop: 0
                    },
                    backgroundColor: theme.colors.background.accent
                },
                beta: {
                    width: '50%',
                    button: {
                        marginTop: toRem(20)
                    },
                    backgroundColor: theme.colors.background.accent
                },
                gamma: {
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingRight: toRem(30),
                    paddingTop: toRem(90),
                    button: {
                        marginLeft: 0,
                        fontSize: toRem(14),
                        marginTop: toRem(25)
                    },
                    backgroundColor: theme.colors.background.light
                }
            },
            text: {
                title: {
                    variant: {
                        alpha: {
                            fontSize: toRem(24)
                        },

                        beta: {
                            fontSize: toRem(24)
                        },

                        gamma: {
                            fontSize: toRem(18)
                        }
                    }
                },
                content: {
                    variant: {
                        alpha: {
                            fontSize: toRem(16)
                        },

                        beta: {
                            fontSize: toRem(14)
                        },

                        gamma: {
                            fontSize: toRem(14)
                        }
                    }
                },
                variant: {
                    alpha: {
                        color: theme.colors.text.inverted,
                        align: 'left'
                    },

                    beta: {
                        color: theme.colors.text.inverted,
                        align: 'left'
                    },

                    gamma: {
                        color: theme.colors.text.default,
                        align: 'center'
                    }
                }
            },
            ribbon: {
                variant: {
                    alpha: {
                        right: toRem(-10),
                        top: toRem(45)
                    },
                    beta: {
                        right: '5%',
                        top: toRem(-14)
                    },
                    gamma: {
                        right: 'auto',
                        top: toRem(-15)
                    }
                },
                marginTop: toRem(20),
                marginLeft: toRem(20)
            }
        }
    }

    const mainBreakpoint = variant === 'gamma' ? 'lg' : 'md'

    return {
        backgroundColor: config.sm.variant[variant]?.backgroundColor,
        display: 'flex',
        flexDirection: config.sm.variant[variant]?.flexDirection || 'column',
        justifyContent: config.sm.variant[variant]?.justifyContent || 'initial',
        alignItems: config.sm.variant[variant]?.alignItems || 'initial',
        padding: variant === 'gamma' ? 0 : toRem(40),
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            backgroundColor: config.md.variant[variant]?.backgroundColor,
            flexDirection: config.md.variant[variant]?.flexDirection || 'column',
            alignItems: config.md.variant[variant]?.alignItems,
            padding: toRem(40),
            paddingRight: config.md.variant[variant]?.paddingRight,
            paddingTop: config.md.variant[variant]?.paddingTop
        },
        '& .ugc': {
            '&_text': {
                color: config.md.text.variant[variant].color || theme.color.devWarning,
                '&_title': {
                    lineHeight: 1.2,
                    fontWeight: 900,
                    marginBottom: variant === 'gamma' ? 0 : toRem(10),
                    fontSize: config.sm.text.title.variant[variant].fontSize,
                    [theme.breakpoints.up('md')]: {
                        marginBottom: variant === 'gamma' ? 0 : toRem(10),
                        fontSize: config.md.text.title.variant[variant].fontSize,
                        textAlign: config.md.text.variant[variant].align || 'left'
                    }
                },
                '&_content': {
                    display: config.sm.text.content.variant?.[variant]?.display || 'block',
                    lineHeight: 1.4,
                    fontWeight: 600,
                    fontSize: config.sm.text.content.variant[variant].fontSize,
                    [theme.breakpoints.up(mainBreakpoint)]: {
                        display: 'block',
                        fontSize: config.md.text.content.variant[variant].fontSize,
                        textAlign: config.md.text.variant[variant].align || 'left'
                    }
                }
            },
            '&_button': {
                width: config.sm.button.variant[variant].width,
                height: config.sm.button.variant[variant].height,
                minHeight: config.sm.button.variant[variant].height,
                marginTop: config.sm.button.variant[variant].marginTop,
                marginLeft: config.sm.button.variant?.[variant]?.marginLeft || 0,
                fontSize: config.sm.button.variant[variant].fontSize,
                letterSpacing: variant === 'gamma' ? '-0.03em' : 0,
                flexShrink: 0,
                [theme.breakpoints.up(mainBreakpoint)]: {
                    marginLeft: config.md.variant[variant]?.button?.marginLeft,
                    marginTop: config.md.variant[variant]?.button?.marginTop
                }
            },
            '&_ribbon': {
                display: config.sm.ribbon.variant[variant].display || 'block',
                top: config.sm.ribbon.variant[variant].top,
                right: config.sm.ribbon.variant[variant].right,
                [theme.breakpoints.up(mainBreakpoint)]: {
                    display: 'block',
                    right: config.md.ribbon.variant[variant].right,
                    top: config.md.ribbon.variant[variant].top
                },
                '&_icon': {
                    width: toRem(48),
                    height: toRem(48),
                    marginTop: config.sm.ribbon.variant[variant].marginTop,
                    marginLeft: config.sm.ribbon.variant[variant].marginLeft,
                    [theme.breakpoints.up(mainBreakpoint)]: {
                        marginTop: config.md.ribbon.marginTop,
                        marginLeft: config.md.ribbon.marginLeft
                    }
                }
            },
            '&_decoration': {
                position: 'absolute',
                bottom: 0,
                left: 0,
                display: config.sm.graphic.variant?.[variant]?.display || 'block',
                [theme.breakpoints.up(mainBreakpoint)]: {
                    display: 'block'
                }
            }
        }
    }
}

export default style
