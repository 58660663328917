import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { NavigationActions } from '@hmn/rtl-web-core/actions'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { Cell, Grid } from '../LayoutBuilder.style'

function WidgetGroup({ position: groupPosition, widgets, logoColor, backgroundColor, isGrouped, deviceProp }) {
    const [currentWidthValue = 767] = useBreakpoints(['lg', 'md'], [1024, 768], 767)
    const dispatch = useDispatch()
    const [ref, inView] = useInView({ treshold: 0.5, skip: !logoColor })
    useEffect(() => {
        if (logoColor) {
            if (inView) {
                dispatch(NavigationActions.setLogoColor(logoColor))
            } else {
                dispatch(NavigationActions.setLogoColor(undefined))
            }
        }
    }, [inView, dispatch, logoColor])
    return (
        <Cell
            data-upscore-zone="widget-group"
            className="groupWrapper"
            position={groupPosition}
            backgroundColor={backgroundColor}
            logoColor={logoColor}
            isGrouped={isGrouped}
            ref={ref}>
            <Grid>
                {(widgets || []).map(({ Component, props, key, position }) => {
                    if (!Component) {
                        return null
                    }
                    // eslint-disable-next-line react/prop-types
                    const needWrapping = currentWidthValue <= 768 && props?.richtext
                    if (needWrapping) {
                        return (
                            <div key={key} className="widgetWrapper">
                                <Component position={position} deviceProp={deviceProp} {...props} />
                            </div>
                        )
                    }
                    return <Component key={key} position={position} deviceProp={deviceProp} {...props} />
                })}
            </Grid>
        </Cell>
    )
}

WidgetGroup.propTypes = {
    position: PropTypes.shape({}),
    widgets: PropTypes.arrayOf(PropTypes.shape({})),
    logoColor: PropTypes.string,
    isGrouped: PropTypes.bool,
    backgroundColor: PropTypes.string,
    deviceProp: PropTypes.string
}
WidgetGroup.defaultProps = {
    position: {},
    widgets: [],
    logoColor: '',
    isGrouped: true,
    backgroundColor: '',
    deviceProp: undefined
}

export default WidgetGroup
