// eslint-disable-next-line @typescript-eslint/naming-convention
import Script from 'next/script'

import { useIsCroatiaCountryCode } from '@hmn/rtl-web-core/context/CountryCode.context'
import { useEnvironment } from '@hmn/rtl-web-core/hooks'

export const TaboolaScript = () => {
    const { thirdPartyEnabled } = useEnvironment()
    const isHR = useIsCroatiaCountryCode()
    if (!thirdPartyEnabled || isHR) {
        return null
    }
    return <Script src="//pahtzc.tech/c/www.net.hr.js" id="taboola-net" async />
}

export const TaboolaFrameIds = Object.freeze({
    PARENT: 'tab_relateds',
    FIRST_BLOCK: 'tab_first_block',
    SECOND_BLOCK: 'tab_second_block'
})

/**
 * Wrap all children that need to be included into the feed
 */
export const TaboolaProvider = ({ children }) => (
    <div id={TaboolaFrameIds.PARENT}>
        {children}
        <TaboolaScript />
    </div>
)

export const TaboolaFrame = ({
    id
}: {
    id: (typeof TaboolaFrameIds)[keyof typeof TaboolaFrameIds]
    children?: never
}) => (
    <div id={id}>
        {/* <div className="debug" style={{ width: 200, height: 150, background: 'red' }}>
            Taboola goes here
        </div> */}
    </div>
)

export const TaboolaFirstFrame = () => <TaboolaFrame id={TaboolaFrameIds.FIRST_BLOCK} />
export const TaboolaSecondFrame = () => <TaboolaFrame id={TaboolaFrameIds.SECOND_BLOCK} />
