import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { VoyoSliderWithData } from '@hmn/rtl-web-core/components/VoyoSlider/VoyoSlider.component'

import Image from '@hmn/rtl-net-ui/components/Image/Image.component'
import { toRems } from '@hmn/rtl-net-ui/helpers'

import { PredsjIzboriKandidati } from '../../izbori/2024-predsjednicki/PredsjIzboriKandidati'
import { Cell } from '../LayoutBuilder.style'

const PREDSJ_KANDIDATI = ['07425c88-b6de-11ef-8b51-9ec7cd506fba', '33461fd0-b7d9-11ef-b113-36bdb4de2687']

function WidgetVoyoSlider({ position, isWide, id, deviceProp, device }) {
    const isAmp = useAmp()
    if (!id || isAmp) {
        return null
    }

    const isPredsjIzbori = PREDSJ_KANDIDATI.includes(id)

    if (isPredsjIzbori) {
        return (
            <Cell
                position={position}
                noPadding
                style={{
                    position: 'relative',
                    alignSelf: 'stretch',
                    padding: 0,
                    margin: toRems([0, 0, 15])
                }}>
                <PredsjIzboriKandidati id={id} ImageComponent={Image} />
            </Cell>
        )
    }

    if (!position) {
        return (
            <VoyoSliderWithData
                ImageComponent={Image}
                isWide={isWide}
                id={id}
                deviceProp={deviceProp}
                device={device}
            />
        )
    }
    return (
        <Cell
            position={position}
            noPadding
            style={{
                position: 'relative',
                alignSelf: 'stretch',
                padding: 0,
                margin: toRems([0, 0, 15])
            }}>
            <VoyoSliderWithData
                isWidget
                ImageComponent={Image}
                isWide={position?.w === 12 && isWide}
                id={id}
                deviceProp={deviceProp}
                device={device}
            />
        </Cell>
    )
}
WidgetVoyoSlider.propTypes = {
    position: PropTypes.shape({ w: PropTypes.number, h: PropTypes.number }),
    deviceProp: PropTypes.string,
    device: PropTypes.string,
    isWide: PropTypes.bool,
    id: PropTypes.string
}
WidgetVoyoSlider.defaultProps = {
    position: undefined,
    deviceProp: undefined,
    device: undefined,
    isWide: true,
    id: undefined
}

export default WidgetVoyoSlider
