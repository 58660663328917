import React from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'

import IzboriLayout from '../styles/euAnkete.style'
import { useEuIzboriContext } from './EuIzboriContext'
// import Header from './Header'
import { PartyCardsDisplay } from './PartyCards'
import { resultsPropTypes } from './resultsPropTypes'

export function EuIzboriListe({ results, landingUrl }) {
    // console.log('EuIzboriListe', results)
    return (
        <IzboriLayout className="izbori" partiesList={results?.lista}>
            <Link href={landingUrl} target="_blank" title="EU izbori 2024" className="izbori__head">
                <img src="/images/izbori/eu-izbori-logo.svg" alt="Parlamentarni izbori 2024" className="eu_izbori" />
                <div className="izbori__head_info is_liste">
                    <p className="source">
                        <span className="mob">
                            Izvor: <strong>DIP</strong>
                        </span>
                        <span className="desk">
                            Izvor: <strong>Državno Izborno Povjerenstvo</strong>
                        </span>
                        <span>
                            Obrađeno: <strong>{`${results.posto}%`}</strong>
                        </span>
                    </p>
                </div>
            </Link>

            {/* TODO: make a component */}
            {/* <Header
                headerData={[
                    { text: 'Izvor:', value: 'DIP', className: 'mob' },
                    { text: 'Izvor:', value: 'Državno Izborno Povjerenstvo', className: 'desk' },
                    { text: 'Obrađeno:', value: `${results.posto}%` }
                ]}
            /> */}
            <div className="izbori__parties">
                <PartyCardsDisplay items={results?.lista} isPoll />
            </div>
        </IzboriLayout>
    )
}

EuIzboriListe.propTypes = {
    results: resultsPropTypes.isRequired,
    landingUrl: PropTypes.string
}

EuIzboriListe.defaultProps = {
    landingUrl: ''
}

export function EuIzboriListeWithData({ landing }) {
    const { results } = useEuIzboriContext()
    if (!results) {
        return null
    }
    return <EuIzboriListe results={results} landingUrl={landing} />
}

EuIzboriListeWithData.propTypes = {
    landing: PropTypes.string
}

EuIzboriListeWithData.defaultProps = {
    landing: ''
}
