import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import WidgetIframe from '@hmn/rtl-web-core/components/widgets/WidgetIframe'

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetIframeNet({ position, title, source, height, deviceProp, ...rest }) {
    return (
        <Cell position={position}>
            <WidgetIframe {...{ title, source, height, deviceProp }} {...rest} />
        </Cell>
    )
}

WidgetIframeNet.propTypes = {
    position: PropTypes.shape({}),
    title: PropTypes.string,
    source: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deviceProp: PropTypes.string
}
WidgetIframeNet.defaultProps = {
    position: {},
    title: undefined,
    source: undefined,
    height: undefined,
    deviceProp: 'mobile'
}

export default WidgetIframeNet
