// import { toRem } from '../../helpers'

const styles = ({ theme, isAmp, pageCategory }) => ({
    '& .linker-widget-col a span': {
        color: `${theme.colors.linker?.text || theme.colors.devWarning}${!isAmp ? ' !important' : ''}`
    },
    ...(pageCategory === 'e-commerce' && {
        maxHeight: '500px', // has to be in px, because Linker
        [theme.breakpoints.up('md')]: {
            maxHeight: '680px' // has to be in px, because Linker
        }
    })
})

export default styles
