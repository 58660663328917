import { toRem } from '../../../../helpers/theme'

const style = ({ theme, isMenuOpen }) => {
    const config = {
        md: {
            width: '100%'
        },
        sm: {
            width: '100%',
            marginBottom: toRem(25)
        }
    }

    return {
        marginBottom: config.sm.marginBottom,
        [theme.breakpoints.up('md')]: {
            width: toRem(350)
        },
        '.label': {
            fontSize: toRem(14),
            fontWeight: 900,
            lineHeight: 1.4,
            fontFamily: theme.typography.fontFamily,
            paddingLeft: toRem(14)
        },
        '.select': {
            border: `1px solid #666766`,
            fontSize: toRem(16),
            fontWeight: 900,
            lineHeight: 1.4,
            fontFamily: theme.typography.fontFamily,
            width: config.sm.width,
            [theme.breakpoints.up('md')]: {
                width: config.md.width
            },
            '&_input': {
                cursor: 'pointer',
                width: '100%',
                height: toRem(50),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingLeft: toRem(16),
                paddingRight: toRem(16),
                borderBottom: isMenuOpen && `1px solid #666766`,
                '&_icon': {
                    fill: '#080808'
                }
            },
            '&_options': {
                margin: 'auto',
                width: '88%',
                overflow: 'hidden',
                '&_label': {
                    height: toRem(50),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderBottom: `1px solid ${theme.colors.border.default}`
                },
                '&_wrapper': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    paddingTop: toRem(21),
                    paddingBottom: toRem(21)
                },
                '&_hidden': {
                    width: '0',
                    overflow: 'hidden'
                },
                '&_option': {
                    cursor: 'pointer',
                    width: '25%',
                    height: toRem(40),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: `${theme.colors.text.default} !important`,
                    '&_disabled': {
                        width: '25%',
                        height: toRem(40),
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: `${theme.colors.grey400} !important`
                    },
                    '&_selected': {
                        width: '25%',
                        height: toRem(40),
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: theme.colors.background.accent,
                        color: `${theme.colors.text.white} !important`
                    },
                    '&:hover': {
                        backgroundColor: theme.colors.background.accent,
                        color: `${theme.colors.text.white} !important`
                    }
                }
            }
        }
    }
}

export default style
