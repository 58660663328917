const colors = {
    white: '#FFFFFF',
    borderGrey: 'rgba(67, 67, 67, 0.2)',
    textWhite: '#FFFFFF',
    textDark: '#1e2a33'
}

const styles = ({ theme }) => ({
    '&.izbori__card': {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        overflow: 'hidden',
        margin: '5px 4.5px 7px',
        flex: '0 0 auto',
        width: 'calc(33% - 8px)',
        height: '170px',
        border: `1px solid ${colors.borderGrey}`,
        borderRadius: '3px',

        '&.is_candidate': {
            width: '130px',
            height: '240px',

            '@media (min-width: 768px)': {
                width: '180px',
                height: '327px'
            }
        },

        '@media (min-width: 768px)': {
            width: '140px',
            height: '224px',
            margin: '5px 19px 7px',

            '&:first-of-type': {
                marginLeft: '60px'
            },

            '&:last-of-type': {
                marginRight: '60px'
            }
        },

        '& .izbori__card_num': {
            marginTop: '12px',
            lineHeight: '1.1',
            color: colors.textWhite,
            fontSize: '45px',
            fontWeight: '700',
            userSelect: 'none',
            textShadow: '2px 2px 7px rgba(0, 0, 0, 0.25)',

            span: {
                display: 'inline-block',
                '@media (min-width: 768px)': {
                    scale: '0.95 1'
                }
            },

            '@media (min-width: 768px)': {
                fontSize: '60px'
            }
        },

        '& .izbori__card_label': {
            display: 'block',
            alignItems: 'center',
            color: colors.textWhite,
            height: '12px',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '7px',
            textTransform: 'uppercase',
            userSelect: 'none',
            textShadow: '2px 2px 7px rgba(0, 0, 0, 0.25)',

            '@media (min-width: 768px)': {
                height: '31px',
                fontSize: '16px',
                lineHeight: '12px'
            }
        },

        '& .izbori__card_img': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '60px',
            marginTop: '55px',

            img: {
                width: 'fit-content'
            }
        },

        '& .candidate_meta ': {
            fontFamily: 'proxima-nova,ArialToProximaNova,sans-serif',
            background: 'rgba(255, 255, 255, 0.9)',

            '& > p': {
                padding: '0 8px 4px',
                fontSize: '10px',
                fontWeight: '500',
                lineHeight: '12px',
                color: colors.textDark,
                textAlign: 'left',

                '&.name': {
                    margin: '5px 0 5px',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '15px',
                    textTransform: 'capitalize',
                    '&:first-letter, &:first-line': {
                        textTransform: 'capitalize'
                    }
                },
                '&.party': {
                    fontWeight: '700'
                },
                '&.num': {},

                '@media (min-width: 768px)': {
                    fontSize: '14px',
                    lineHeight: '16px',

                    '&.name': {
                        fontSize: '18px',
                        lineHeight: '20px'
                    }
                }
            }
        }
    }
})

export default styles
