import { toRem } from '../../../../helpers/theme'

const style = ({ theme, variant }) => {
    const config = {
        sm: {
            text: {
                title: {
                    fontSize: toRem(24)
                },
                content: {
                    fontSize: toRem(16)
                }
            },
            ribbon: {
                right: toRem(-10),
                top: toRem(20),
                marginTop: toRem(12),
                marginLeft: toRem(10)
            },
            button: {
                marginTop: toRem(28)
            },
            input: {
                flexDirection: 'column',
                field: {
                    marginBottom: toRem(10)
                }
            }
        },
        md: {
            variant: {
                alpha: {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingRight: toRem(80),
                    button: {
                        marginLeft: '7%',
                        marginTop: 0
                    },
                    input: {
                        flexDirection: 'row',
                        field: {
                            marginBottom: toRem(0)
                        }
                    }
                },
                beta: {
                    button: {
                        marginTop: toRem(26)
                    },
                    input: {
                        flexDirection: 'row',
                        field: {
                            marginBottom: toRem(0)
                        }
                    }
                },
                gamma: {
                    input: {
                        flexDirection: 'column',
                        field: {
                            marginBottom: toRem(10)
                        }
                    }
                }
            },
            text: {
                title: {
                    fontSize: toRem(24)
                },
                content: {
                    fontSize: toRem(16)
                }
            },
            ribbon: {
                variant: {
                    alpha: {
                        right: toRem(-10),
                        top: toRem(35)
                    },
                    beta: {
                        right: '5%',
                        top: toRem(-14)
                    },
                    gamma: {
                        right: '5%',
                        top: toRem(-14)
                    }
                },
                marginTop: toRem(20),
                marginLeft: toRem(15)
            }
        }
    }
    return {
        backgroundColor: theme.colors.background.light,
        display: 'flex',
        flexDirection: 'column',
        padding: '25px 25px 10px',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            padding: '40px 40px 10px',
            flexDirection: config.md.variant[variant]?.flexDirection || 'column',
            justifyContent: config.md.variant[variant]?.justifyContent,
            alignItems: config.md.variant[variant]?.alignItems,
            paddingRight: config.md.variant[variant]?.paddingRight
        },
        '& .newsletter': {
            '&_text': {
                marginTop: variant === 'alpha' ? '-35px' : 0,

                '&_title': {
                    color: theme.colors.text.accent,
                    lineHeight: 1.2,
                    fontWeight: 900,
                    fontSize: config.sm.text.title.fontSize,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.text.title.fontSize
                    }
                },
                '&_content': {
                    color: theme.colors.text.default,
                    fontWeight: 600,
                    lineHeight: 1.4,
                    fontSize: config.sm.text.content.fontSize,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.text.content.fontSize
                    }
                }
            },
            '&_ribbon': {
                top: toRem(20),
                right: toRem(-10),
                [theme.breakpoints.up('md')]: {
                    right: config.md.ribbon.variant[variant].right,
                    top: config.md.ribbon.variant[variant].top
                },
                '&_icon': {
                    width: toRem(48),
                    height: toRem(48),
                    marginTop: config.sm.ribbon.marginTop,
                    marginLeft: config.sm.ribbon.marginLeft,
                    [theme.breakpoints.up('md')]: {
                        marginTop: config.md.ribbon.marginTop,
                        marginLeft: config.md.ribbon.marginLeft
                    }
                }
            },
            '&_input': {
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: config.sm.input.flexDirection,
                marginTop: config.sm.button.marginTop,
                [theme.breakpoints.up('md')]: {
                    flexDirection: config.md.variant[variant].input.flexDirection,
                    marginLeft: config.md.variant[variant]?.button?.marginLeft,
                    marginTop: config.md.variant[variant]?.button?.marginTop
                },
                '&_button': {
                    maxWidth: toRem(113),
                    height: toRem(50)
                },
                '&_fieldWrapper': {
                    width: toRem(260),
                    marginBottom: config.sm.input.field.marginBottom,
                    [theme.breakpoints.up('md')]: {
                        marginBottom: config.md.variant[variant]?.input?.field?.marginBottom
                    }
                },
                '&_field': {
                    borderRadius: 0,
                    border: 'none',
                    paddingLeft: toRem(15),
                    width: '100%',
                    height: toRem(50),
                    backgroundColor: theme.colors.background.input
                },
                '&_field:focus': {
                    outline: `1px solid ${theme.colors.text.accent}`,
                    backgroundColor: theme.colors.background.default
                },
                '&_field::-webkit-input-placeholder': {
                    fontFamily: 'proxima-nova,Arial,sans-serif',
                    fontStyle: 'italic',
                    color: theme.colors.text.placeHolder,
                    fontSize: toRem(16)
                },
                '&_success': {
                    width: '100%',
                    marginTop: toRem(11),
                    color: '#20AB3F',
                    fontSize: toRem(14),
                    fontWeight: 700,
                    lineHeight: 1.4,
                    fontStyle: 'italic'
                },
                '&_fail': {
                    width: '100%',
                    marginTop: toRem(11),
                    color: theme.colors.survey.input.text.error,
                    fontSize: toRem(14),
                    fontWeight: 700,
                    lineHeight: 1.4,
                    fontStyle: 'italic'
                }
            },
            '&_decoration': {
                position: 'absolute',
                bottom: 0,
                left: 0
            },
            '&_form_status': {
                height: '30px',
                fontSize: toRem(14),
                lineHeight: '30px'
            }
        }
    }
}

export default style
