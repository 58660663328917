/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const ArrowNet = createSvgIcon(
    <path
        fill="#F15B24"
        fillRule="evenodd"
        d="M8.1 6.4c.5.5.5 1.2 0 1.7l-5.5 5.5A1.3 1.3 0 011 12l4.6-4.7L.9 2.7A1.2 1.2 0 112.6 1l5.5 5.5z"
        clipRule="evenodd"
    />,
    'ArrowNet'
)

export default ArrowNet
