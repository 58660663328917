import { Children, cloneElement, isValidElement, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { articleCardSizeVariants, articleCardVariants } from '../Article/components/Card'
import { articleSpecialSizeVariants, articleSpecialVariants } from '../Article/components/Special'
import styles from './Breaking.style'

const BreakingStyled = styled.section(props => ({ ...styles(props) }))
function Breaking({ children, className, ...rest }) {
    const uid = useUIDSeed()
    const itemElements = useMemo(() => Children.toArray(children).filter(child => isValidElement(child)), [children])

    if (!itemElements.length) {
        return null
    }
    return (
        <BreakingStyled className={className} {...rest}>
            <div className="netBreaking_inner">
                <div className="netBreaking_list">
                    {itemElements?.slice(0, 4).map((child, index) => (
                        <div
                            key={uid(child?.props?.item?.id || index)}
                            className={clsx('netBreaking_item', index === 0 && 'isFirst')}>
                            {cloneElement(child, {
                                variant: index === 0 ? articleSpecialVariants.BETA : articleCardVariants.BETA,
                                sizeVariant:
                                    index === 0 ? articleSpecialSizeVariants['1x1'] : articleCardSizeVariants['1x6'],
                                lazyLoad: true
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </BreakingStyled>
    )
}

Breaking.propTypes = {
    className: PropTypes.string
}
Breaking.defaultProps = {
    className: undefined
}

export default withErrorBoundary(Breaking, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Breaking]: ', error, componentStack)
    }
})
