import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { Ribbon } from '../../../Decoration/components'
import {
    AquariusIcon,
    AriesIcon,
    CancerIcon,
    CapricornIcon,
    GeminiIcon,
    Icon,
    LeoIcon,
    LibraIcon,
    PiscesIcon,
    SagittariusIcon,
    ScorpioIcon,
    TaurusIcon,
    VirgoIcon
} from '../../../Icon'
import { Link } from '../../../Link'
import styles from './Zodiac.net.style'

const getZodiacOrder = (isMobile, variant, signs) => {
    if (!signs?.length) {
        return []
    }
    let order
    if (isMobile) {
        order = [
            { icon: AriesIcon, title: 'Ovan' },
            { icon: TaurusIcon, title: 'Bik' },
            { icon: GeminiIcon, title: 'Blizanci' },
            { icon: CancerIcon, title: 'Rak' },
            { icon: LeoIcon, title: 'Lav' },
            { icon: VirgoIcon, title: 'Djevica' },
            { icon: LibraIcon, title: 'Vaga' },
            { icon: ScorpioIcon, title: 'Škorpion' },
            { icon: SagittariusIcon, title: 'Strijelac' },
            { icon: CapricornIcon, title: 'Jarac' },
            { icon: AquariusIcon, title: 'Vodenjak' },
            { icon: PiscesIcon, title: 'Ribe' }
        ]
    } else if (variant === 'beta') {
        order = [
            { icon: AriesIcon, title: 'Ovan' },
            { icon: TaurusIcon, title: 'Bik' },
            { icon: GeminiIcon, title: 'Blizanci' },
            { icon: CancerIcon, title: 'Rak' },
            { icon: LeoIcon, title: 'Lav' },
            { icon: VirgoIcon, title: 'Djevica' },
            { icon: LibraIcon, title: 'Vaga' },
            { icon: ScorpioIcon, title: 'Škorpion' },
            { icon: SagittariusIcon, title: 'Strijelac' },
            { icon: CapricornIcon, title: 'Jarac' },
            { icon: AquariusIcon, title: 'Vodenjak' },
            { icon: PiscesIcon, title: 'Ribe' }
        ]
    } else {
        order = [
            { icon: AriesIcon, title: 'Ovan' },
            { icon: TaurusIcon, title: 'Bik' },
            { icon: GeminiIcon, title: 'Blizanci' },
            { icon: CancerIcon, title: 'Rak' },
            { icon: LeoIcon, title: 'Lav' },
            { icon: VirgoIcon, title: 'Djevica' },
            { icon: LibraIcon, title: 'Vaga' },
            { icon: ScorpioIcon, title: 'Škorpion' },
            { icon: SagittariusIcon, title: 'Strijelac' },
            { icon: CapricornIcon, title: 'Jarac' },
            { icon: AquariusIcon, title: 'Vodenjak' },
            { icon: PiscesIcon, title: 'Ribe' }
        ]
    }
    return order?.map(sign => {
        const signTemp = signs.find(item => item.name === sign.title)
        return { ...sign, href: signTemp?.path }
    })
}

const ZodiacStyled = styled.div(props => ({ ...styles(props) }))

function Zodiac({ variant, signList, ...rest }) {
    const uid = useUIDSeed()
    const [isDesktop] = useBreakpoints('md')
    const signs = getZodiacOrder(!isDesktop, variant, signList)

    if (!signs?.length) {
        return null
    }

    return (
        <ZodiacStyled variant={variant} {...rest}>
            <Link href="/webcafe/dnevni-horoskop" title="Horoskop">
                <Ribbon className="zodiac_ribbon" title="Horoskop" />
            </Link>
            <div className="zodiac_signs">
                {signs?.map((item, index) => (
                    <ConditionalWrapper
                        className="zodiac_sign"
                        key={uid(item?.id || index)}
                        condition={!!item.href}
                        Wrapper={Link}
                        href={item.href}
                        as={item.href}
                        title={item.title}>
                        {item.href ? (
                            <>
                                <Icon className="zodiac_sign_icon" icon={item.icon} />
                                <div className="zodiac_sign_title">{item.title}</div>
                            </>
                        ) : (
                            <div className="zodiac_sign">
                                <Icon className="zodiac_sign_icon" icon={item.icon} />
                                <div className="zodiac_sign_title">{item.title}</div>
                            </div>
                        )}
                    </ConditionalWrapper>
                ))}
            </div>
        </ZodiacStyled>
    )
}

const zodiacVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

Zodiac.propTypes = {
    variant: PropTypes.oneOf([...Object.values(zodiacVariants)]),
    signList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

Zodiac.defaultProps = {
    variant: zodiacVariants.ALPHA
}

export { zodiacVariants }

Zodiac.displayName = 'Zodiac'

export default withErrorBoundary(Zodiac, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Zodiac]: ', error, componentStack)
    }
})
