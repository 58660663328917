import PropTypes from 'prop-types'

const listItemPropTypes = PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string,
    score: PropTypes.number,
    color: PropTypes.string,
    title: PropTypes.string
})

listItemPropTypes.defaultProps = {
    slug: 'default',
    name: '',
    score: 0,
    color: '#BBB',
    title: 'default'
}

export const resultsPropTypes = PropTypes.shape({
    bmPosto: PropTypes.string, // Used in EuIzboriKandidati
    posto: PropTypes.string, // Used in EuIzboriListe
    biraciGlasovalo: PropTypes.number, // Used in EuIzboriAnkete
    lista: PropTypes.arrayOf(listItemPropTypes).isRequired
})
