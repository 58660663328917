import { toRem } from '../../../../helpers/theme'

const style = ({ theme, variant }) => {
    const config = {
        sm: {
            height: toRem(351),
            signs: {
                justifyContent: 'space-between',
                margin: 'auto',
                width: '70%',
                paddingTop: toRem(30),
                paddingBottom: toRem(10)
            },
            sign: {
                justifyContent: 'flex-start',
                width: '40%',
                height: '12%',
                fontSize: toRem(16),
                icon: {
                    marginRight: toRem(5),
                    marginTop: toRem(6),
                    width: toRem(24),
                    height: toRem(24)
                }
            }
        },
        md: {
            alpha: {
                height: toRem(120),
                sign: {
                    justifyContent: 'flex-start',
                    width: '16%',
                    height: '45%',
                    fontSize: toRem(18),
                    icon: {
                        marginRight: toRem(13),
                        marginTop: toRem(10),
                        width: toRem(22),
                        height: toRem(22)
                    }
                }
            },
            beta: {
                height: toRem(167),
                sign: {
                    justifyContent: 'flex-start',
                    width: '25%',
                    height: '30%',
                    fontSize: toRem(18),
                    icon: {
                        marginRight: toRem(13),
                        marginTop: toRem(10),
                        width: toRem(22),
                        height: toRem(22)
                    }
                }
            },
            signs: {
                justifyContent: 'center',
                width: '85%',
                paddingTop: 0,
                paddingBottom: 0
            }
        }
    }
    return {
        width: '100%',
        backgroundColor: theme.colors.background.light,
        height: config.sm.height,
        [theme.breakpoints.up('md')]: {
            height: config.md[variant].height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        '& .zodiac': {
            '&_ribbon': {
                [theme.breakpoints.down('sm')]: {
                    top: toRem(15)
                }
            },
            '&_signs': {
                display: 'flex',
                flexWrap: 'wrap',
                height: '90%',
                margin: config.sm.signs.margin,
                width: config.sm.signs.width,
                paddingTop: config.sm.signs.paddingTop,
                paddingBottom: config.sm.signs.paddingBottom,
                justifyContent: config.sm.signs.justifyContent,
                [theme.breakpoints.up('md')]: {
                    width: config.md.signs.width,
                    paddingTop: config.md.signs.paddingTop,
                    paddingBottom: config.md.signs.paddingBottom,
                    justifyContent: config.sm.signs.justifyContent
                }
            },
            '&_sign': {
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                lineHeight: 1.6,
                color: theme.colors.text.default,
                justifyContent: config.sm.sign.justifyContent,
                width: config.sm.sign.width,
                height: config.sm.sign.height,
                fontSize: config.sm.sign.fontSize,
                [theme.breakpoints.up('md')]: {
                    justifyContent: config.md[variant].sign.justifyContent,
                    width: config.md[variant].sign.width,
                    height: config.md[variant].sign.height,
                    fontSize: config.md[variant].sign.fontSize
                },
                '&_icon': {
                    width: config.sm.sign.icon.width,
                    height: config.sm.sign.icon.height,
                    marginTop: config.sm.sign.icon.marginTop,
                    marginRight: config.sm.sign.icon.marginRight,
                    [theme.breakpoints.up('md')]: {
                        marginRight: config.md[variant].sign.icon.marginRight,
                        width: config.md[variant].sign.icon.width,
                        height: config.md[variant].sign.icon.height,
                        marginTop: config.md[variant].sign.icon.marginTop
                    }
                }
            }
        }
    }
}

export default style
