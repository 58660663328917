import React from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'

import IzboriLayout from '../styles/euAnkete.style'
import { useEuIzboriContext } from './EuIzboriContext'
import { PartyCardsDisplay } from './PartyCards'
import { resultsPropTypes } from './resultsPropTypes'
// import Footer from './Footer'
// import Header from './Header'

export function EuIzboriAnkete({ results, landingUrl }) {
    // console.log('EuIzboriAnkete', results)
    return (
        <IzboriLayout className="izbori" partiesList={results.lista}>
            <Link href={landingUrl} target="_blank" title="EU izbori 2024" className="izbori__head">
                <img src="/images/izbori/eu-izbori-logo.svg" alt="Parlamentarni izbori 2024" className="eu_izbori" />
                <div className="izbori__head_info">
                    <p className="title">Izlazne ankete</p>
                    <p className="source">
                        <span>
                            Izvor: <strong>Ipsos</strong>
                        </span>
                        <span>
                            n = <strong>{`${results.biraciGlasovalo}`}</strong>
                        </span>
                    </p>
                </div>
            </Link>

            {/* TODO: make a component */}
            {/* <Header
                title="Izlazne ankete"
                headerData={[
                    { text: 'Izvor:', value: 'Ipsos' },
                    { text: 'n =', value: `${results.biraciGlasovalo}` }
                ]}
            /> */}
            <div className="izbori__parties">
                <PartyCardsDisplay items={results?.lista} isPoll />
            </div>

            <div className="izbori__parties-foot">
                <p className="source">
                    <span>
                        Izvor: <strong>Ipsos</strong>
                    </span>
                    <span>
                        n = <strong>{`${results.biraciGlasovalo}`}</strong>
                    </span>
                </p>
            </div>

            {/* TODO: make a component */}
            {/* <Footer
                sourceInfo={[
                    { text: 'Izvor:', value: 'Ipsos' },
                    { text: 'n =', value: `${results.biraciGlasovalo}` }
                ]}
            /> */}
        </IzboriLayout>
    )
}

EuIzboriAnkete.propTypes = {
    results: resultsPropTypes.isRequired,
    landingUrl: PropTypes.string
}

EuIzboriAnkete.defaultProps = {
    landingUrl: ''
}

export function EuIzboriAnketeWithData({ landing }) {
    const { poll } = useEuIzboriContext()
    if (!poll) {
        return null
    }
    return <EuIzboriAnkete results={poll} landingUrl={landing} />
}

EuIzboriAnketeWithData.propTypes = {
    landing: PropTypes.string
}

EuIzboriAnketeWithData.defaultProps = {
    landing: ''
}
