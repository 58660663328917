import { useMemo } from 'react'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import aboveTheFoldItemsNumberHelper from '@hmn/rtl-web-core/helpers/aboveTheFoldItemsNumberHelper'

import { ArticleSpecial, articleSpecialSizeVariants } from '@hmn/rtl-net-ui/components/Article/components'

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetSpecial({ items, title, subtitle, lead, position }) {
    const numberOfItemsInViewPort = useMemo(
        () => aboveTheFoldItemsNumberHelper(1, position.w, position.y, position.h),
        [position]
    )

    if (items.length === 0) {
        return null
    }

    return (
        <Cell position={position}>
            <ArticleSpecial
                sizeVariant={articleSpecialSizeVariants[`1x${12 / position.w}`]}
                item={items[0]}
                title={title}
                subtitle={subtitle}
                lead={lead}
                imageLazyLoad={!numberOfItemsInViewPort}
                imagePreload={numberOfItemsInViewPort}
            />
        </Cell>
    )
}

WidgetSpecial.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    lead: PropTypes.string,
    position: PropTypes.shape({
        y: PropTypes.number,
        w: PropTypes.number,
        h: PropTypes.number
    }),
    items: PropTypes.arrayOf(PropTypes.shape({}))
}
WidgetSpecial.defaultProps = {
    title: undefined,
    subtitle: undefined,
    lead: undefined,
    position: undefined,
    items: []
}

export default WidgetSpecial
