// import { toRem, toRems } from '../../../../helpers/theme'

import { toRem } from '../../../../helpers/theme'

const style = ({ theme, isText }) => {
    const config = {
        sm: {
            width: '100%',
            maxWidth: toRem(420),
            height: toRem(350),
            text: {
                title: {
                    fontSize: toRem(20)
                },
                content: {
                    fontSize: toRem(16)
                }
            },
            ribbon: {
                top: toRem(15)
            }
        },
        md: {
            width: '30%',
            minWidth: toRem(350),
            height: toRem(350),
            text: {
                title: {
                    fontSize: toRem(24)
                },
                content: {
                    fontSize: toRem(16)
                }
            },
            ribbon: {
                top: toRem(30)
            }
        }
    }
    return {
        position: 'relative',
        backgroundColor: theme.colors.background.light,
        width: config.sm.width,
        height: config.sm.height,
        [theme.breakpoints.up('md')]: {
            width: config.md.width,
            minWidth: config.md.minWidth,
            height: config.md.height
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: config.sm.maxWidth
        },
        '& .fun': {
            '&_ribbon': {
                top: config.sm.ribbon.top,
                [theme.breakpoints.up('md')]: {
                    top: config.md.ribbon.top
                },
                position: 'absolute',
                zIndex: 10,
                span: {
                    color: theme.colors.text.accent
                }
            },
            '&_text': {
                paddingTop: isText ? toRem(70) : toRem(30),
                width: '80%',
                margin: 'auto',
                '&_title': {
                    fontWeight: 'bold',
                    lineHeight: 1.2,
                    fontSize: config.sm.text.title.fontSize,
                    color: theme.colors.text.default,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.text.title.fontSize
                    },
                    marginBottom: toRem(10)
                },
                '&_content': {
                    maxHeight: toRem(170),
                    overflowY: 'hidden',
                    p: {
                        lineHeight: 1.4,
                        marginBottom: toRem(10),
                        fontWeight: 400,
                        fontSize: config.sm.text.content.fontSize,
                        [theme.breakpoints.up('md')]: {
                            fontSize: config.md.text.content.fontSize
                        }
                    },
                    marginBottom: toRem(10)
                },
                '&_button': {
                    color: theme.colors.text.accent,
                    textDecoration: 'underline',
                    textTransform: 'none',
                    marginBottom: toRem(30),
                    fontSize: config.sm.text.content.fontSize,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.text.content.fontSize
                    }
                }
            }
        },
        '& .fun_text_content::after': {
            content: '""',
            position: 'absolute',
            zIndex: 1,
            bottom: 70,
            left: 0,
            pointerEvents: 'none',
            backgroundImage: 'linear-gradient(to bottom, #f6f6f621, #f6f6f6 90%)',
            width: '100%',
            height: toRem(30)
        },
        '& .Image-isLoaded': {
            height: '100%',
            padding: 'inherit'
        },
        img: {
            height: '100%'
        }
    }
}

export default style
