/* eslint-disable @typescript-eslint/naming-convention */
import styled from '@emotion/styled'
import React from 'react'

import { ConditionalWrapper } from '../ConditionalWrapper'
import Icon from '../Icon/Icon.component'
import VoyoLogoIcon from '../Icon/icons/VoyoLogo'
import { VoyoCtaStyled } from './VoyoSlider.style'

const A = styled.a()
export const VoyoCallToAction = ({
    buttonHref,
    subtitle,
    className
}: {
    buttonHref?: string
    subtitle?: string
    className?: string
}) => (
    <ConditionalWrapper
        condition={!!buttonHref}
        Wrapper={A as any}
        href={buttonHref}
        target="_blank"
        title={buttonHref}>
        {/* @ts-expect-error: icon props are muffed */}
        <VoyoCtaStyled className={className}>
            {/* @ts-expect-error: icon props are muffed */}
            <Icon icon={VoyoLogoIcon} size={40} width="71" height="20" viewBox="0 0 71 20" className="VoyoCta_voyo" />
            <span className="VoyoCta_custom-message">
                <strong>{subtitle ? subtitle : 'Gledaj odmah bez reklama'}</strong>
            </span>
        </VoyoCtaStyled>
    </ConditionalWrapper>
)
