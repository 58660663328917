import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

const ArticlePoll = dynamic(() => import('./ArticlePoll.local.component').then(m => m.ArticlePoll), { ssr: false })
const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetPoll({ position, id }) {
    return (
        <Cell position={position}>
            <ArticlePoll id={id} />
        </Cell>
    )
}
WidgetPoll.propTypes = {
    position: PropTypes.shape({}),
    id: PropTypes.string
}
WidgetPoll.defaultProps = {
    position: {},
    id: ''
}

export default WidgetPoll
