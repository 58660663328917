import { toRem, toRems } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ isEmpty, theme }) => ({
    ...(!isEmpty && {
        display: 'flex',
        alignItems: 'center',
        '&:before': {
            width: '100%',
            content: '""',
            height: 5,
            background: theme.colors.grey200
        },
        '&:after': {
            width: '100%',
            content: '""',
            height: 5,
            background: theme.colors.grey200
        }
    }),
    ...(isEmpty && { height: toRem(128) }),
    padding: toRems([30, 0]),
    [theme.breakpoints.up('md')]: {
        padding: toRems([60, 0])
    }
})

export default style
