import { ComponentType } from '../helpers'
import WidgetBanner from './WidgetBanner.component'
import WidgetBreaking from './WidgetBreaking.component'
import WidgetContent from './WidgetContent.component'
import WidgetDivider from './WidgetDivider.component'
import WidgetFun from './WidgetFun.component'
//* ******* WIDGET IMPORTS *********//
import WidgetGroup from './WidgetGroup.component'
import WidgetHeading from './WidgetHeading.component'
import WidgetHoroskop from './WidgetHoroskop.component'
import WidgetIframe from './WidgetIframe.component'
import WidgetLinker from './WidgetLinker.component'
import WidgetNewsletter from './WidgetNewsletter.component'
import WidgetPhotoVideo from './WidgetPhotoVideo.component'
import WidgetPoll from './WidgetPoll.component'
import WidgetProductGalleries from './WidgetProductGalleries.component'
import WidgetRichtext from './WidgetRichtext.component'
import WidgetSpecial from './WidgetSpecial.component'
import WidgetUgc from './WidgetUgc.component'
import WidgetVoyoSlider from './WidgetVoyoSlider.component'

const widgetComponent = {
    [ComponentType.GROUP]: WidgetGroup,
    [ComponentType.CONTENT_HANDPICKED]: WidgetContent,
    [ComponentType.SPECIAL]: WidgetSpecial,
    [ComponentType.CONTENT_AUTOMATIC]: WidgetContent,
    [ComponentType.HEADING]: WidgetHeading,
    [ComponentType.DIVIDER]: WidgetDivider,
    [ComponentType.RICHTEXT]: WidgetRichtext,
    [ComponentType.BANNER]: WidgetBanner,
    [ComponentType.POLL]: WidgetPoll,
    [ComponentType.IFRAME]: WidgetIframe,
    [ComponentType.PRODUCTGALLERIES]: WidgetProductGalleries,
    [ComponentType.FUN]: WidgetFun,
    [ComponentType.BREAKINGNEWS]: WidgetBreaking,
    [ComponentType.PHOTOVIDEO]: WidgetPhotoVideo,
    [ComponentType.NEWSLETTER]: WidgetNewsletter,
    [ComponentType.UGCCALL]: WidgetUgc,
    [ComponentType.LINKER]: WidgetLinker,
    [ComponentType.HOROSCOPE]: WidgetHoroskop,
    [ComponentType.PLAY_SLIDER]: WidgetVoyoSlider
}

export default widgetComponent
