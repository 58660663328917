import styled from '@emotion/styled'

import { toRem } from '../../../../helpers/theme'

const style = () => ({
    marginTop: toRem(15),
    '.list': {
        '&_category': {
            fontSize: toRem(20),
            fontWeight: 700,
            lineHeight: 1.7,
            marginBottom: toRem(5)
        },
        '&_subcategory': {
            '&_level1': {
                listStyleType: 'disc',
                paddingLeft: toRem(30)
            },
            '&_level2': {
                listStyleType: 'disc',
                marginLeft: toRem(60)
            },
            '&_button': {
                lineHeight: 1.7,
                fontSize: toRem(18),
                fontWeight: '400'
            }
        }
    }
})

export const Divider = styled.div(({ level, comparedToPrev }) => {
    let height
    if (comparedToPrev === 'greater') {
        if (level === 1) {
            height = toRem(8)
        } else {
            height = toRem(16)
        }
    } else if (comparedToPrev === 'smaller') {
        if (level === 1) {
            height = toRem(5)
        } else {
            height = toRem(8)
        }
    }
    return {
        width: '100%',
        height
    }
})

export default style
