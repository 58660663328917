import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import SectionTitle, {
    netSectionTitleSizeVariants,
    netSectionTitleStyleVariants
} from '@hmn/rtl-net-ui/components/SectionTitle/SectionTitle.net.component'

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetHeading({ url, title, position, color, style }) {
    return (
        <Cell position={position}>
            <SectionTitle
                title={title}
                titleUrl={url}
                sizeVariant={netSectionTitleSizeVariants[`1x${Math.round(12 / position.w)}`]}
                borderColor={color}
                styleVariant={style === '1' ? netSectionTitleStyleVariants.ALPHA : netSectionTitleStyleVariants.BETA}
                titleTag="h3"
            />
        </Cell>
    )
}
WidgetHeading.propTypes = {
    position: PropTypes.shape({
        w: PropTypes.number
    }),
    style: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string
}
WidgetHeading.defaultProps = {
    position: {},
    style: undefined,
    color: undefined,
    title: '',
    url: undefined
}

export default WidgetHeading
