// @NOTE: must be in sync with and subset of WidgetTypes from libs/shared/nest-core/src/features/elasticsearch/helpers/layoutBuilder.helper.js
// @TODO: connect to shared export
const ComponentType = {
    GROUP: 'GROUP',
    CONTENT_HANDPICKED: 'CONTENT_HANDPICKED',
    CONTENT_AUTOMATIC: 'CONTENT_AUTOMATIC',
    HEADING: 'HEADING',
    DIVIDER: 'DIVIDER',
    NAVIGATION: 'NAVIGATION',
    RICHTEXT: 'RICHTEXT',
    SPECIAL: 'SPECIAL',
    BORDER_BOX: 'BORDER_BOX',
    BANNER: 'BANNER',
    POLL: 'Poll',
    IFRAME: 'IFRAME',
    PRODUCTGALLERIES: 'PRODUCTGALLERIES',
    FUN: 'FUN',
    BREAKINGNEWS: 'BREAKINGNEWS',
    PHOTOVIDEO: 'PHOTOVIDEO',
    NEWSLETTER: 'NEWSLETTER',
    UGCCALL: 'UGCCALL',
    LINKER: 'LINKER',
    HOROSCOPE: 'HOROSCOPE',
    PLAY_SLIDER: 'PLAY_SLIDER'
}

export default ComponentType
