import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import slugify from '../helpers/slugify'
import styles from './EuPartyCard.style'

const EuCandidateStyled = styled.div(props => ({ ...styles(props) }))
const EuPartyCardStyled = styled.div(props => ({ ...styles(props) }))

function EuCandidateCard({ id, naziv, listaNaziv, glasova, metaParty, zamjenaZa }) {
    const partyClass = slugify(metaParty.titleShort)
    return (
        <EuCandidateStyled className={`izbori__card is_${partyClass} is_candidate`} title={naziv} layoutId={partyClass}>
            <img
                width="180"
                height="226"
                src={`/images/izbori/candidates/${id}.jpg`}
                alt={naziv}
                onError={e => {
                    e.currentTarget.src = '/images/izbori/candidates/candidate_placeholder.png'
                }}
            />
            <div className="candidate_meta">
                <p className="name">{naziv.toLowerCase()}</p>
                <p className="party">{metaParty.titleShort}</p>
                <p className="num">
                    {zamjenaZa ? (
                        <span style={{ fontSize: '0.9em' }}>(umjesto {zamjenaZa})</span>
                    ) : (
                        <>
                            <strong>Broj glasova:</strong> {glasova}
                        </>
                    )}
                </p>
            </div>
        </EuCandidateStyled>
    )
}

EuCandidateCard.propTypes = {
    id: PropTypes.string.isRequired,
    naziv: PropTypes.string.isRequired,
    listaNaziv: PropTypes.string.isRequired,
    glasova: PropTypes.number.isRequired,
    metaParty: PropTypes.shape({
        titleShort: PropTypes.string.isRequired
    }).isRequired,
    zamjenaZa: PropTypes.string
}

EuCandidateCard.defaultProps = {
    zamjenaZa: ''
}

export function EuPartyCard({ name, score, title, slug, pid }) {
    return (
        <EuPartyCardStyled className={`izbori__card is_${slug}`} title={title} layoutId={slug}>
            <p className="izbori__card_num">
                <span>{score}</span>
            </p>
            <p className="izbori__card_label">{score > 1 ? 'Mandata' : 'Mandat'}</p>
            <div className="izbori__card_img img-err">
                <img
                    src={`/images/izbori/party_logos/logo_${pid}.png`}
                    alt={name.toLowerCase()}
                    onError={e => {
                        e.currentTarget.classList.add('imgErr')
                    }}
                />
                <div className="imgError_alt">{name.toLowerCase()}</div>
            </div>
        </EuPartyCardStyled>
    )
}

EuPartyCard.propTypes = {
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    pid: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired
}

export default EuCandidateCard
