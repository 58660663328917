import { useQuery } from 'react-query'

import { getListElastic, getOneElastic } from '@hmn/rtl-web-core/hooks'
import { handleError, returnEmptyOn4xx } from '@hmn/rtl-web-core/next/data-fetching/handle-errors'

import RequestType from './requestType'

const useWidgetDataSync = requestList => {
    const { data: widgetData = [] } = useQuery(
        ['widget-data', requestList],
        async () => {
            const settled = await Promise.allSettled(
                requestList.map(request => {
                    switch (request.type) {
                        case RequestType.ONE_ENTITY:
                            return getOneElastic(`entity/${request?.entityId}`, { enabled: !!request?.entityId }).catch(
                                handleError(returnEmptyOn4xx)
                            )
                        case RequestType.LIST_FROM_CATEGORY:
                            return getListElastic(`category/${request?.nodeId}/entities`, {}, request?.entityCount, {
                                enabled: !!request?.nodeId
                            })
                        default:
                            return null
                    }
                })
            )
            return settled.map(({ value }) => value?.data)
        },
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 30 * 1000,
            cacheTime: process.browser ? 1000 * 30 : Infinity,
            retry: false
        }
    )

    return widgetData
}

export default useWidgetDataSync
