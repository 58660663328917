import ComponentType from './componentType'
import RequestType from './requestType'

const getRequestList = widgetList => {
    const requestList = []

    widgetList.forEach((widget, index) => {
        const priority = widget.position.y < widgetList[0].position.y + 5
        switch (widget.component) {
            case ComponentType.CONTENT_HANDPICKED:
            case ComponentType.SPECIAL:
            case ComponentType.BREAKINGNEWS:
            case ComponentType.PHOTOVIDEO:
                {
                    const type = RequestType.ONE_ENTITY
                    const { entityList } = widget.config
                    entityList.forEach(({ entity, entityId }) => {
                        const foundItem = requestList.find(item => item.type === type && item.entityId === entityId)
                        if (!foundItem) {
                            requestList.push({
                                type,
                                priority,
                                entity,
                                entityId
                            })
                        } else {
                            foundItem.priority = foundItem.priority || priority
                        }
                    })
                }
                break
            case ComponentType.CONTENT_AUTOMATIC:
            case ComponentType.BORDER_BOX:
                {
                    const type = RequestType.LIST_FROM_CATEGORY
                    const { nodeId, entityCount } = widget.config
                    const foundItem = requestList.find(item => item.type === type && item.nodeId === nodeId)
                    if (!foundItem) {
                        requestList.push({
                            type,
                            priority,
                            nodeId,
                            entityCount,
                            distribution: {
                                [index]: [0, entityCount]
                            }
                        })
                    } else {
                        const nextCount = foundItem.entityCount + entityCount
                        foundItem.distribution[index] = [foundItem.entityCount, nextCount]
                        foundItem.entityCount = nextCount
                        foundItem.priority = foundItem.priority || priority
                    }
                }
                break

            default:
                break
        }
    })

    return requestList
}
export default getRequestList
