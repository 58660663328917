import React from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'

import IzboriLayout from '../styles/euAnkete.style'
import { useEuIzboriContext } from './EuIzboriContext'
// import Header from './Header'
import CandidateCards from './PartyCards'
import { resultsPropTypes } from './resultsPropTypes'

export function EuIzboriKandidati({ results, landingUrl }) {
    // console.log('EuIzboriKandidati', results)
    return (
        <IzboriLayout className="izbori" partiesList={results.lista}>
            <Link href={landingUrl} target="_blank" title="EU izbori 2024" className="izbori__head">
                <img src="/images/izbori/eu-izbori-logo.svg" alt="EU izbori 2024" className="eu_izbori" />
                <div className="izbori__head_info">
                    <p className="title">Hrvatski zastupnici u EU parlamentu</p>
                    <p className="source">
                        <span>
                            Izvor: <strong>Državno Izborno Povjerenstvo</strong>
                        </span>
                        <span>
                            Obrađeno: <strong>{results.bmPosto}</strong>
                        </span>
                    </p>
                </div>
            </Link>

            {/* TODO: make a component */}
            {/* <Header
                title="Hrvatski zastupnici u EU parlamentu"
                headerData={[
                    { text: 'Izvor:', value: 'Državno Izborno Povjerenstvo' },
                    { text: 'Obrađeno:', value: results.bmPosto }
                ]}
            /> */}
            <div className="izbori__parties">
                <CandidateCards items={results.lista} />
            </div>
        </IzboriLayout>
    )
}

EuIzboriKandidati.propTypes = {
    results: resultsPropTypes.isRequired,
    landingUrl: PropTypes.string
}

EuIzboriKandidati.defaultProps = {
    landingUrl: ''
}

export function EuIzboriKandidatiWithData({ landing }) {
    const { candidates } = useEuIzboriContext()
    if (!candidates) {
        return null
    }
    return <EuIzboriKandidati results={candidates} landingUrl={landing} />
}

EuIzboriKandidatiWithData.propTypes = {
    landing: PropTypes.string
}

EuIzboriKandidatiWithData.defaultProps = {
    landing: ''
}
