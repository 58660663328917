/* eslint-disable no-console */
/* eslint-disable no-plusplus */

import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import { useRouter } from 'next/router'
import Script from 'next/script'
import PropTypes from 'prop-types'

import { InitialLoadActions } from '@hmn/rtl-web-core/actions'
import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useEnvironment, useInterval } from '@hmn/rtl-web-core/hooks'
import useUpdatedRef from '@hmn/rtl-web-core/hooks/functional/useUpdatedRef'

import styles from './Linker.style'

const linkerCategoryMap = {
    'e-commerce': '529',
    vijesti: '384',
    danas: '384',
    sport: '386',
    magazin: '392',
    hot: '390',
    webcafe: '388',
    overkloking: '384',
    'cura-dana': '388',
    'vic-dana': '392',
    gallery: '402',
    izdvojeno: '385',
    footer: '236',
    default: '384'
}

const altLinkerCategoryMap = {
    vijesti: '702',
    webcafe: '703',
    hot: '704',
    sport: '706',
    magazin: '705'
}

const getLinkerCode = (category, availableIdMap) => availableIdMap[category] || availableIdMap.default
const initialLoadSelector = state => state.initialLoad

const LinkerStyled = styled.div(props => ({ ...styles(props) }))
function Linker({ pageCategory, altIdList }) {
    const { thirdPartyEnabled } = useEnvironment()
    const isAmp = useAmp()
    const dispatch = useDispatch()
    const { linkerLwLoaded, linkerSplideLoaded } = useSelector(initialLoadSelector)

    const linkerCode = useMemo(
        () => getLinkerCode(pageCategory, altIdList ? altLinkerCategoryMap : linkerCategoryMap),
        [pageCategory, altIdList]
    )

    const linkerCodeRef = useUpdatedRef(linkerCode)

    const mountedRef = useRef(false)

    const reloadWidget = useCallback(() => {
        if (!linkerCode) {
            return
        }
        if (thirdPartyEnabled && process?.browser && window?.reloadLinkerElements) {
            // console.log(`reloading widget ${pageCategory}`)
            window.reloadLinkerElements(linkerCode)
        }
    }, [linkerCode, thirdPartyEnabled])

    const setSplideLoaded = useCallback(
        () => !linkerSplideLoaded && dispatch(InitialLoadActions.setLinkerSplideLoaded(true)),
        [linkerSplideLoaded]
    )
    const setLwLoaded = useCallback(
        () => !linkerLwLoaded && dispatch(InitialLoadActions.setLinkerLwLoaded(true)),
        [linkerLwLoaded]
    )

    const checkSplide = () => {
        // eslint-disable-next-line
        if (window?.Splide && !linkerSplideLoaded) {
            setSplideLoaded()
            return true
        }
        if (linkerSplideLoaded) {
            return true
        }
        return false
    }

    useInterval(
        cleanUp => {
            if (checkSplide()) {
                cleanUp()
            }
        },
        { delay: 3000, enabled: pageCategory === 'e-commerce' && thirdPartyEnabled, runImmediately: true }
    ) // @NOTE: final backup in case Splide is further delayed in loading

    const router = useRouter()
    useEffect(() => {
        const onRouteChangeComplete = () => {
            if (!linkerCodeRef.current) {
                return
            }
            reloadWidget()
            mountedRef.current = true
        }

        router.events.on('routeChangeComplete', onRouteChangeComplete)
        return () => {
            mountedRef.current = false
            router.events.off('routeChangeComplete', onRouteChangeComplete)
        }
    }, [])

    useEffect(() => {
        if (!linkerCode) {
            return
        }
        if (
            thirdPartyEnabled &&
            !mountedRef.current &&
            linkerLwLoaded &&
            (pageCategory !== 'e-commerce' || (pageCategory === 'e-commerce' && linkerSplideLoaded))
        ) {
            reloadWidget()
            mountedRef.current = true
        }
    }, [reloadWidget, pageCategory, linkerLwLoaded, linkerSplideLoaded, thirdPartyEnabled, linkerCode])
    if (!thirdPartyEnabled || !linkerCode) {
        return null
    }
    return (
        <>
            {pageCategory === 'e-commerce' && !linkerSplideLoaded && (
                <Script
                    src="https://linker.hr/widget/slider/splide.min.js"
                    onLoad={setSplideLoaded}
                    async
                    defer
                    strategy="lazyOnload"
                />
            )}
            <Script src="https://linker.hr/lw-spa.js" onLoad={setLwLoaded} async defer strategy="lazyOnload" />
            <LinkerStyled
                className="lwdgt"
                id="linker"
                isAmp={isAmp}
                data-wid={linkerCode}
                pageCategory={pageCategory}
            />
        </>
    )
}

Linker.propTypes = {
    pageCategory: PropTypes.string,
    altIdList: PropTypes.bool
}

Linker.defaultProps = {
    pageCategory: undefined,
    altIdList: false
}

function AmpLinker({ id }) {
    const { thirdPartyEnabled } = useEnvironment()
    return (
        thirdPartyEnabled && (
            <div
                className="Linker"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: `<amp-iframe
                          width="300"
                          height="1640"
                          layout="responsive"
                          sandbox="allow-scripts allow-same-origin"
                          src="https://d.linker.hr/amp-lw?wid=${id}"
                        >
                        <amp-img layout="fill" src='/images/placeholder/amp-iframe-net.png' placeholder/>
                    </amp-iframe>`
                }}
            />
        )
    )
}

AmpLinker.propTypes = {
    id: PropTypes.string
}

AmpLinker.defaultProps = {
    id: undefined
}

export { AmpLinker }
export default withErrorBoundary(Linker, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Linker]: ', error, componentStack)
    }
})
