/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'
import SwiperCore, { Keyboard, Lazy, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Draggable } from '@hmn/rtl-web-core/components/Draggable.component'

import { OptimizedEuIzboriData, OptimizedEuKandidatiData } from '../../../helpers/optimizeEuIzboriData'
import EuCandidateCard, { EuPartyCard } from '../PartyCard/EuPartyCard.component'

SwiperCore.use([Keyboard, Lazy, Navigation, Pagination])

const CandidateCards = ({ items }: { items: OptimizedEuKandidatiData['lista'] }) => {
    if (!items) return null

    const content = (
        <div className={'izbori__parties-cards'}>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={12}
                navigation
                breakpoints={{
                    768: {
                        slidesPerView: 'auto',
                        slidesPerGroup: 6
                    }
                }}>
                {items.map((candidate, index) => (
                    <SwiperSlide key={candidate.slug}>
                        <EuCandidateCard
                            key={candidate.id}
                            id={candidate.id}
                            naziv={candidate.naziv}
                            listaNaziv={candidate.listaNaziv}
                            glasova={candidate.glasova}
                            zamjenaZa={candidate.zamjenaZa}
                            metaParty={candidate.metaParty}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )

    return content
}

export const PartyCardsDisplay = ({
    items,
    isPoll
}: {
    items: OptimizedEuIzboriData['lista']
    isResults?: boolean
    isPoll?: boolean
}) => {
    if (!items) return null

    const content = (
        <div className={'izbori__parties-cards'}>
            {items.map(party => (
                <EuPartyCard
                    key={party.pid}
                    name={party.name || party.title}
                    title={party.title || party.name}
                    slug={party.slug}
                    score={party.score}
                    pid={party.pid}
                />
            ))}
        </div>
    )
    if (isPoll)
        return (
            <Draggable direction="x" className={'izbori__parties-cards-draggable'}>
                {content}
            </Draggable>
        )
    return content
}

export default CandidateCards
