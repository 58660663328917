import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

// eslint-disable-next-line max-len
import UserGeneratedContent from '@hmn/rtl-net-ui/components/Cta/components/UserGeneratedContent/UserGeneratedContent.net.component'

const Cell = dynamic(() => import('../LayoutBuilder.style').then(m => m.Cell))

function WidgetUgc({ position }) {
    return (
        <Cell position={position}>
            <div className="userGeneratedContent">
                <UserGeneratedContent variant={position.w > 6 ? 'alpha' : 'beta'} />
            </div>
        </Cell>
    )
}
WidgetUgc.propTypes = {
    position: PropTypes.shape({
        w: PropTypes.number
    })
}
WidgetUgc.defaultProps = {
    position: {}
}

export default WidgetUgc
