/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../utils'

const VoyoLogo = createSvgIcon(
    // width="71" height="20" viewBox="0 0 71 20" fill="none" xmlns="http://www.w3.org/2000/svg"
    <>
        <path
            d="M30.9818 9.9168C31.0673 7.7553 29.3573 5.14349 26.5358 5.14349C23.7142 5.14349 22.0042 7.66524 22.0042 9.82674C22.0042 12.2584 23.7997 14.6 26.5358 14.6C29.2718 14.6901 31.0673 12.1684 30.9818 9.9168ZM35.2569 9.9168C35.3424 14.6901 31.5803 19.1932 26.4503 19.1932C21.3202 19.1932 17.6437 14.6901 17.6437 9.82674C17.7292 5.14349 21.3202 0.640366 26.5358 0.640366C31.6658 0.640366 35.3424 5.14349 35.2569 9.9168Z"
            fill="white"
        />
        <path
            d="M56.7175 9.9168C56.7175 12.5286 58.7695 14.6901 61.249 14.6901C63.6431 14.6901 65.7806 12.7087 65.7806 9.9168C65.7806 7.30499 63.7286 5.14349 61.249 5.14349C58.7695 5.14349 56.7175 7.30499 56.7175 9.9168ZM61.5055 19.1932C56.3755 19.1932 52.4424 15.0504 52.5279 9.82674C52.5279 4.78324 56.5465 0.640366 61.42 0.640366C63.8141 0.640366 65.8661 1.63105 67.5761 3.4323C69.2861 5.23355 70.1411 7.48511 70.1411 9.9168C70.1411 12.4385 69.2861 14.6901 67.5761 16.4914C65.7806 18.2926 63.6431 19.1032 61.5055 19.1932Z"
            fill="white"
        />
        <path
            d="M8.83714 12.1683C9.17914 11.2677 9.43565 10.4572 9.77765 9.55653C10.6327 7.12485 11.5732 4.78322 12.4282 2.35154C12.8557 1.27079 13.7107 0.730413 14.6512 0.730413C16.1902 0.820476 17.1307 2.35154 16.5322 3.8826C15.9337 5.50372 15.2497 7.03479 14.6512 8.65591C13.5397 11.5379 12.4282 14.3298 11.2312 17.2118C10.8037 18.3827 9.94865 19.0131 8.83714 19.0131C7.55462 19.0131 6.69962 18.4727 6.18661 17.3019C4.39109 12.8888 2.68107 8.47578 0.885553 4.06272C0.287046 2.62172 1.05655 1.18073 2.25357 0.910538C3.45058 0.550288 4.56209 1.18073 4.9896 2.4416C5.84461 4.87329 6.78512 7.21491 7.64013 9.6466C7.89663 10.3671 8.23863 11.1777 8.49513 11.8982C8.49513 11.9882 8.58064 12.0783 8.58064 12.1683C8.75164 12.1683 8.75164 12.1683 8.83714 12.1683Z"
            fill="white"
        />
        <path
            d="M43.9779 7.57531C44.3199 7.03494 44.6619 6.58463 44.9184 6.13431C45.8589 4.78338 46.7139 3.34238 47.6544 1.99144C48.1674 1.18088 48.8514 0.730568 49.7919 0.730568C50.8179 0.730568 51.673 1.54113 51.7585 2.62188C51.844 3.34238 51.5875 4.06288 51.16 4.60325C49.5354 6.85482 47.9964 9.10638 46.3719 11.3579C46.1154 11.7182 46.0299 11.9884 46.0299 12.4387C46.0299 13.8797 46.0299 15.2306 46.0299 16.6716C46.0299 17.8424 45.1749 18.8331 44.1489 18.8331C42.9519 18.9232 41.9258 18.1126 41.7548 16.9418C41.7548 16.6716 41.6693 16.4014 41.6693 16.1312C41.6693 14.7803 41.6693 13.5194 41.6693 12.1685C41.6693 11.9884 41.5838 11.7182 41.4983 11.6281C39.8738 9.2865 38.2493 6.94488 36.6248 4.69332C36.1973 4.15294 35.9408 3.5225 36.0263 2.802C36.1118 1.81132 36.7103 1.18088 37.5653 0.910693C38.5058 0.640505 39.2753 0.910693 39.8738 1.63119C40.5578 2.53182 41.1563 3.5225 41.8404 4.51319C42.4389 5.41382 43.0374 6.31444 43.6359 7.21506C43.8069 7.39519 43.8924 7.48525 43.9779 7.57531Z"
            fill="white"
        />
    </>,
    'VoyoLogo'
)

export default VoyoLogo
