/* eslint-disable quote-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import safelyJoinSlugs from '@hmn/rtl-web-core/helpers/safelyJoinSlugs'

import { CalendarNetIcon, Icon } from '../../../Icon'
import { Link } from '../../../Link'
import styles from './DatePicker.style'

const months = [
    'Siječanj',
    'Veljača',
    'Ožujak',
    'Travanj',
    'Svibanj',
    'Lipanj',
    'Srpanj',
    'Kolovoz',
    'Rujan',
    'Listopad',
    'Studeni',
    'Prosinac'
]

const getYears = years => {
    // @NOTE WEBCAFFE HAS 0201 AS A YEAR
    const yearsFiltered = years?.filter(year => year !== '0201')
    const yearStart = parseInt(yearsFiltered[0], 10)
    const yearEnd = parseInt(yearsFiltered[yearsFiltered.length - 1], 10)
    const yearsFormated = Array.from({ length: yearEnd - yearStart + 1 }, (_, index) => (yearStart + index).toString())
    return yearsFormated
}

const DatePickerStyled = styled.div(props => ({ ...styles(props) }))

function DatePicker({ className, slugs, initialYear, initialMonth, data, pageTitle: pageTitleInput, ...rest }) {
    const [selectedYear, setSelectedYear] = useState()
    const [selectedMonth, setSelectedMonth] = useState()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const uid = useUIDSeed()

    useEffect(() => {
        setSelectedYear()
        setSelectedMonth()
    }, [initialYear, initialMonth])

    if (!data) {
        return null
    }
    const years = getYears(Object.keys(data))

    const pageTitle = pageTitleInput

    const handleYearSelect = option => {
        setSelectedYear(option)
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    const handleMonthSelect = option => {
        toggleMenu()
        if (selectedMonth !== option) {
            setSelectedMonth(option)
        }
    }

    if (className) {
        return null
    }
    return (
        <DatePickerStyled className={className} isMenuOpen={isMenuOpen} {...rest}>
            <span className="label">Arhiva / Odaberi period</span>
            <div className="select">
                <div className="select_input" id="archive_select_button" onClick={toggleMenu}>
                    <span>{`${selectedYear || initialYear || ''} ${
                        selectedMonth ? `/ ${selectedMonth}` : initialMonth || ''
                    }`}</span>
                    <Icon className="select_input_icon" icon={CalendarNetIcon} viewBox="0 0 11 11" />
                </div>
                <div className="select_options" style={{ height: isMenuOpen ? 'auto' : 0 }}>
                    <div
                        className="select_year_tab"
                        style={{
                            height: !selectedYear || (selectedYear && selectedMonth) ? 'auto' : 0,
                            overflow: 'hidden'
                        }}>
                        <div className="select_options_label">Godina</div>
                        <div className="select_options_wrapper">
                            {years?.map((year, index) => {
                                if (!data[year]) {
                                    return (
                                        <div key={uid(index)} className="select_options_option_disabled">
                                            {year}
                                        </div>
                                    )
                                }
                                return (
                                    <div
                                        key={uid(index)}
                                        className={`select_options_option${initialYear === year ? '_selected' : ''}`}
                                        onClick={() => handleYearSelect(year)}>
                                        {year}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div
                        className="select_month_tab"
                        style={{
                            height: selectedYear && !selectedMonth ? 'auto' : 0,
                            overflow: 'hidden'
                        }}>
                        {years.map(year => (
                            <div
                                key={uid(year)}
                                style={{
                                    height: year === selectedYear ? 'auto' : 0,
                                    overflow: 'hidden'
                                }}>
                                <div className="select_options_label">Mjesec</div>
                                <div className="select_options_wrapper">
                                    {months?.map((month, index) => {
                                        // @NOTE: there are some years without all months
                                        const findItem = data[year]?.find(
                                            element => parseInt(element.month, 10) === index + 1
                                        )
                                        if (!findItem || !findItem?.doc_count) {
                                            return (
                                                <div key={uid(index)} className="select_options_option_disabled">
                                                    {month}
                                                </div>
                                            )
                                        }

                                        return (
                                            <Link
                                                href={`/${safelyJoinSlugs(slugs)}/arhiva/${year}/${
                                                    index <= 8 ? 0 : ''
                                                }${index + 1}`}
                                                title={`${pageTitle ? `${pageTitle} / ` : ''}Arhiva, ${month} ${year}`}
                                                key={uid(index)}
                                                className={`select_options_option${
                                                    initialMonth === `/ ${index + 1}` ? '_selected' : ''
                                                }`}
                                                onClick={() => handleMonthSelect(`${index <= 8 ? 0 : ''}${index + 1}`)}>
                                                <span className="select_options_hidden">{`${
                                                    pageTitle ? `${pageTitle} / ` : ''
                                                }Arhiva, `}</span>
                                                {month}
                                                <span className="select_options_hidden">{` ${year}`}</span>
                                            </Link>
                                        )
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </DatePickerStyled>
    )
}

DatePicker.propTypes = {
    className: PropTypes.string,
    slugs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    initialYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.shape({}),
    pageTitle: PropTypes.string
}
DatePicker.defaultProps = {
    className: undefined,
    slugs: undefined,
    initialYear: undefined,
    initialMonth: undefined,
    data: undefined,
    pageTitle: undefined
}

export default withErrorBoundary(DatePicker, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[DatePicker]: ', error, componentStack)
    }
})
