import { PropsWithChildren, useRef, useState } from 'react'

type DraggableProps = PropsWithChildren<{
    className?: string
    direction?: 'x' | 'y'
    walkMultiplier?: number
}>

export const Draggable = ({ className = '', children, direction, walkMultiplier = 1.5 }: DraggableProps) => {
    const ourRef = useRef<HTMLDivElement>(null)
    const [isMouseDown, setIsMouseDown] = useState(false)
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    })
    const isVertical = direction !== 'x'
    const isHorizontal = direction !== 'y'
    const handleDragStart = e => {
        if (!ourRef.current) return
        const slider = ourRef.current.children[0] as HTMLElement
        if (!slider) return
        const startX = isHorizontal ? e.pageX - slider.offsetLeft : 0
        const startY = isVertical ? e.pageY - slider.offsetTop : 0
        const scrollLeft = isHorizontal ? slider.scrollLeft : 0
        const scrollTop = isVertical ? slider.scrollTop : 0
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
        setIsMouseDown(true)
        document.body.style.cursor = 'grabbing'
    }
    const handleDragEnd = () => {
        setIsMouseDown(false)
        if (!ourRef.current) return
        document.body.style.cursor = 'default'
    }
    const handleDrag = e => {
        if (!isMouseDown || !ourRef.current) return
        e.preventDefault()
        const slider = ourRef.current.children[0] as HTMLElement
        if (!slider) return
        const x = e.pageX - slider.offsetLeft
        const y = e.pageY - slider.offsetTop
        const walkX = (x - mouseCoords.current.startX) * walkMultiplier
        const walkY = (y - mouseCoords.current.startY) * walkMultiplier
        if (isHorizontal) slider.scrollLeft = mouseCoords.current.scrollLeft - walkX
        if (isVertical) slider.scrollTop = mouseCoords.current.scrollTop - walkY
    }

    return (
        <div
            ref={ourRef}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onMouseMove={handleDrag}
            className={className + 'flex overflow-x-scroll'}>
            {children}
        </div>
    )
}
