import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { Button, netButtonVariants } from '../../../Button'
import {
    Graphic,
    netGraphicFillVariants,
    netGraphicVariants,
    Ribbon,
    ribbonVariants
} from '../../../Decoration/components'
import { Icon, UgcIcon } from '../../../Icon'
import styles from './UserGeneratedContent.net.style'

const UserGeneratedContentStyled = styled.div(props => ({ ...styles(props) }))

function UserGeneratedContent({ className, variant, hasTitleLineBreak, ...rest }) {
    const [isDesktop] = useBreakpoints('md')
    const [isLarge] = useBreakpoints('lg')
    return (
        <UserGeneratedContentStyled className={className} variant={variant} {...rest}>
            <div className="ugc_text" id="ugc_text">
                <div className="ugc_text_title" id="ugc_text_title">
                    Imaš priču?{' '}
                    {(!isDesktop && variant !== 'beta' && !(isLarge && variant === 'gamma')) ||
                    (hasTitleLineBreak && isDesktop) ? (
                        <br />
                    ) : null}
                    Javi nam se!
                </div>
                <div className="ugc_text_content" id="ugc_text_content">
                    Imaš priču, ekskluzivu ili jednostavno temu za koju bi se trebalo čuti? Javi nam se, a mi ti jamčimo
                    anonimnost.
                </div>
            </div>
            <Button
                className="ugc_button"
                id="ugc_button"
                variant={netButtonVariants.BETA}
                isInverted={variant !== 'gamma'}
                href="/vase-vijesti">
                Pošalji priču
            </Button>
            <Ribbon
                className="ugc_ribbon"
                id="ugc_ribbon"
                variant={ribbonVariants.BETA}
                iconComponent={
                    <Icon icon={UgcIcon} id="ugc_ribbon_icon" className="ugc_ribbon_icon" viewBox="0 0 48 48" />
                }
            />
            <Graphic
                className="ugc_decoration"
                variant={netGraphicVariants.ALPHA}
                fillVariant={netGraphicFillVariants[variant === 'gamma' ? 'ALTERNATIVE' : 'DEFAULT']}
                heightMd={10}
                heightXs={10}
                widthMd="100%"
                widthXs="100%"
            />
        </UserGeneratedContentStyled>
    )
}

const userGeneratedContentVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma' // header & footer
})

UserGeneratedContent.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(userGeneratedContentVariants)]),
    hasTitleLineBreak: PropTypes.bool
}

UserGeneratedContent.defaultProps = {
    className: undefined,
    variant: userGeneratedContentVariants.ALPHA,
    hasTitleLineBreak: false
}

export { userGeneratedContentVariants }
export default withErrorBoundary(UserGeneratedContent, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[UserGeneratedContent]: ', error, componentStack)
    }
})
