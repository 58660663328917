import { toRem, toRems } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => {
    const config = {
        sm: {
            head: {
                padding: toRems([20, 25])
            },
            list: {
                padding: toRems([0, 0, 15, 0])
            },
            item: {
                padding: toRems([0, 15, 15, 15])
            }
        },
        md: {
            head: {
                padding: toRems([20, 15])
            },
            list: {
                padding: toRems([0, 25, 25, 25])
            },
            item: {
                padding: 0
            }
        }
    }
    return {
        width: '100%',
        '& .VideoPhotoBlock_inner': {
            backgroundColor: theme.colors.background.default
        },
        '& .VideoPhotoBlock_head': {
            maxWidth: toRem(1250),
            margin: 'auto',
            padding: config.sm.head.padding,
            textTransform: 'uppercase',
            [theme.breakpoints.up('md')]: {
                padding: config.md.head.padding
            }
        },
        '& .VideoPhotoBlock_headTitle': {
            margin: toRems([12, 0, 15]),
            color: theme.colors.text.default,
            borderBottom: `4px solid ${theme.colors.text.accent}`,
            fontSize: toRem(32),
            lineHeight: 1.2,
            width: toRem(180),
            [theme.breakpoints.up('md')]: {
                margin: toRems([20, 0, 10]),
                fontSize: toRem(42),
                width: toRem(470)
            }
        },
        '& .VideoPhotoBlock_list': {
            maxWidth: toRem(1250),
            margin: 'auto',
            padding: config.sm.list.padding,
            [theme.breakpoints.up('md')]: {
                padding: config.md.list.padding,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridRowGap: toRem(30),
                gridColumnGap: toRem(30),
                alignItems: 'center'
            }
        },
        '& .VideoPhotoBlock_item': {
            '&.isFirst': {
                gridColumn: '1/5',
                [theme.breakpoints.down('md')]: {
                    '.cardContent': {
                        color: 'green',
                        paddingLeft: toRem(15)
                    },
                    padding: 0
                }
            },
            padding: config.md.item.padding,
            [theme.breakpoints.down('md')]: {
                padding: config.sm.item.padding,
                marginBottom: toRem(30)
            }
        }
    }
}

export default style
